import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const AuthGuard = ({ children, organizationId, organizationsId, mapFactoryOrganization, otcpMember, path }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (path.startsWith('/vizity') && user.admin !== true) {
    if (organizationId && user.currentOrganizationId !== organizationId) {
      return <Redirect to="/login" />;
    } else if (organizationsId && !organizationsId.includes(user.currentOrganizationId)) {
      return <Redirect to="/login" />;
    } else if (mapFactoryOrganization && !user.isMapFactoryOrganization === true) {
      return <Redirect to="/login" />;
    } else if (otcpMember && !user.isOtcpMember === true) {
      return <Redirect to="/login" />;
    } else if (!(organizationId || organizationsId || mapFactoryOrganization || otcpMember)) {
      return <Redirect to="/login" />;
    }
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  organizationId: PropTypes.number,
  organizationsId: PropTypes.array,
  mapFactoryOrganization: PropTypes.bool,
  path: PropTypes.string
};

export default AuthGuard;
