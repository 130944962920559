import React from 'react';
import PropTypes from 'prop-types';

const EmptyAuthGuard = ({ children }) => {
  return <>{children}</>;
};

EmptyAuthGuard.propTypes = {
  children: PropTypes.node
};

export default EmptyAuthGuard;
