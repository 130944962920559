import React, { useState, useEffect } from 'react';
import { hasPermission } from 'src/utils/permissionUtils';
import useAuth from 'src/hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { PERMISSION_ID } from 'src/vizity/constantsVizity';

const UserSpace = () => {
  const { user } = useAuth();
  const [canAccessUserSpaceMenu] = useState(hasPermission(user, PERMISSION_ID.OTCP_USER_S_SPACE_MENU, true));
  const [userSpace, setUserSpace] = useState();

  useEffect(() => {
    setUserSpace(user.currentOrganizationName);
  }, [user.currentOrganizationName]);

  return (
    canAccessUserSpaceMenu && (
      <Typography variant="h5" color="textSecondary">
        <FormattedMessage id="settings.user.space" defaultMessage="User Space" />
        &nbsp;:&nbsp;
        {userSpace}
      </Typography>
    )
  );
};

export default UserSpace;
