// Temporary global constants : values may change depending on environment database !!!
export const ORGANIZATION_ID = {
  VIZITY: 38,
  OTCP_LOISIR: 50, // ex OTCP
  VIZITY_DEMO: 52,
  OTCP_CONGRES: 53,
  MF_AMADEUS: 54,
  OTCP_PARTENAIRE_1: 81, // musee-nat-sport@otcp.fr : 36y58xYYtNUk Musée National du Sport
  OTCP_PARTENAIRE_2: 82, // partenaire-2@otcp.fr : at6e86KDTWx9
  OTCP_PARTENAIRE_3: 83, // partenaire-3@otcp.fr : 8HKq2Yh2E9vy
  OTCP_PARTENAIRE_4: 84, // partenaire-4@otcp.fr : 6E3AFtrsy6X5
  OTCP_PARTENAIRE_5: 85 // partenaire-5@otcp.fr : 3qqBMkS343hS
};

export const OTCP_ORGANIZATION_IDS = [
  ORGANIZATION_ID.OTCP_LOISIR,
  ORGANIZATION_ID.OTCP_CONGRES,
  ORGANIZATION_ID.OTCP_PARTENAIRE_1,
  ORGANIZATION_ID.OTCP_PARTENAIRE_2,
  ORGANIZATION_ID.OTCP_PARTENAIRE_3,
  ORGANIZATION_ID.OTCP_PARTENAIRE_4,
  ORGANIZATION_ID.OTCP_PARTENAIRE_5
];

/* export */ const OTCP_ORGANIZATION_SETS = new Set(OTCP_ORGANIZATION_IDS);

export const ORGANIZATION_MAP = {
  38: 'Vizity',
  52: 'Map Factory',
  54: 'Amadeus'
};

export const OTCP_USER_SPACE = {
  50: 'OTCP - Loisir',
  53: 'OTCP - Congrès',
  81: 'OTCP - Musée National du Sport',
  82: 'OTCP - Partenaire 2',
  83: 'OTCP - Partenaire 3',
  84: 'OTCP - Partenaire 4',
  85: 'OTCP - Partenaire 5'
};

export const MAP_ORGANIZATION = new Map();
for (const [key, value] of Object.entries(ORGANIZATION_MAP)) {
  MAP_ORGANIZATION.set(Number.parseInt(key), value);
}

for (const [key, value] of Object.entries(OTCP_USER_SPACE)) {
  MAP_ORGANIZATION.set(Number.parseInt(key), value);
}

export const MAP_OTCP_ORGANIZATION = new Map();

for (const [key, value] of Object.entries(OTCP_USER_SPACE)) {
  MAP_OTCP_ORGANIZATION.set(Number.parseInt(key), value);
}

export const isOtcpOrganizationId = organizationId => {
  return OTCP_ORGANIZATION_SETS.has(organizationId);
};

export const PERMISSION_ID = {
  NO_RESTRICTION: -1,
  VIZITY_ADMIN: 49999,
  //-----------------------------
  MAP_FACTORY_PLACES_MAPS: 40101,
  MAP_FACTORY_WALKING_ROUTES_MAPS: 40102,
  MAP_FACTORY_MULTI_WALKING_ROUTES_MAPS: 40103,
  MAP_FACTORY_GOOGLE_SHEETS_MAPS: 40104,
  // ---------------------
  MAP_FACTORY_DEFAULT_SETTINGS: 40201,
  //-----------------------------
  OTCP_USER_S_SPACE_MENU: 40901,
  OTCP_PLACES_MAPS: 40902,
  OTCP_WALKING_ROUTES_MAPS: 40903,
  OTCP_MULTI_WALKING_ROUTES_MAPS: 40904,
  OTCP_CATEGORIES_MAPS: 40905,
  OTCP_UNMISSABLE_PLACES: 40906,
  OTCP_DEFAULT_SETTINGS: 40907,
  OTCP_FACTORY_GOOGLE_SHEETS_MAPS: 40908,
  //-----------------------------
  OTCP_MEMBERS_AROUND_MY_BUSINESS: 40909
};

export const TYPOLOGY_ID = {
  OTCP_PLACES: 1,
  OTCP_WALKING_ROUTES: 2,
  OTCP_MULTI_WALKING_ROUTES: 5,
  OTCP_GOOGLE_SHEETS: 8,
  OTCP_PARTNERS_AROUND_MY_BUSINESS: 9,
  //-----------------------------
  MAP_FACTORY_PLACES: 3,
  MAP_FACTORY_WALKING_ROUTES: 4,
  MAP_FACTORY_MULTI_WALKING_ROUTES: 6,
  MAP_FACTORY_GOOGLE_SHEETS: 7
};

export const MAP_ID = {
  OTCP_ALL_WALKING_ROUTES: 68899
};

export const SHEET_ID = {
  METRO: 124,
  OTCP_FTP_DATA: 199,
  OTCP_PLACES: 208,
  OTCP_WALKING_ROUTES: 209,
  OTCP_WR_POIS: 210,
  OTCP_UNMISSABLE_PLACES: 216,
  OTCP_GOOGLE_SHEETS: 999,
  DEMO: 221
};

export const WIDGET_ID = {
  OTCP_FILTERS_CONTAINER: 305 // use by OTCP_PARTNERS_AROUND_MY_BUSINESS
};

export const SHEET_ID_ZOOM_BLACK_LIST = new Set([SHEET_ID.METRO, SHEET_ID.OTCP_FTP_DATA, SHEET_ID.OTCP_UNMISSABLE_PLACES]);

export const LAYER_ID = {
  LAYER_METRO_PARIS: 167,
  // ----------------------
  LAYER_OTCP_EXPO: 168,
  LAYER_OTCP_POI_NOT_MEMBER: 169,
  LAYER_OTCP_POI_MEMBER: 170,
  LAYER_OTCP_WALKING_ROUTES: 171,
  LAYER_OTCP_WR_POIS: 172,
  LAYER_OTCP_DAE: 173,
  LAYER_OTCP_UNMISSABLE_PLACES: 174,
  LAYER_OTCP_LANDMARK: 178,
  LAYER_OTCP_MULTI_WALKING_ROUTE: 184,
  LAYER_OTCP_MULTI_WALKING_ROUTE_TRANSPARENT: 197,
  // ----------------------
  LAYER_MF_COLORED_CIRCLE: 195,
  LAYER_MF_TEXT: 196,
  LAYER_MF_SPRITE_MARKER: 207,

  // ----------------------
  LAYER_GBO_POI: 888
};

export const PARTNERS_PROD_URL = {
  DEFAULT: 'https://beta.vizity.io/',
  OTCP: 'https://parisinfo.vizity.io/'
};

// ---------------------------------------------------- Permissions ------------------------------------------------------------
export const PERMISSION_MAP = {
  MANAGE: 20001,
  EDIT: 20002,
  CREATE_SHEET: 20101,
  MANAGE_SHEET: 20102,
  EDIT_SHEET: 20103,
  DELETE_SHEET: 20104
};

// ---------------------------------------------------- Default map options ----------------------------------------------------
export const MAP_OPTIONS_CSS = '<link href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i,800,800i&display=swap" rel="stylesheet">';
export const MAP_OPTIONS_MAPBOX_STYLE = 'mapbox://styles/vizity/ck1q51ehh02np1ctauoix7yhu';
export const MAP_OPTIONS_MAPBOX_STYLE_GREY = 'mapbox://styles/lostman/ckolm0m0e23aj18mwayfpwuh7';
export const MAP_OPTIONS_MAPBOX_STYLE_SATELLITE = 'mapbox://styles/mapbox/satellite-v9';

export const MAP_OPTIONS_STYLES_MAP =
  '.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate::before { background-image: none; }  #vizity-map-content .mapboxgl-popup-tip { visibility: hidden; } #vizity-map-content .mapboxgl-popup .mapboxgl-popup-content { padding: 0; pointer-events: none; background-color: transparent; box-shadow: none;}';

/*

    desktopMapWidth: "{{desktopMapWidth}}",
    desktopMapHeight: "{{desktopMapHeight}}",

    mobileMapWidth: "{{mobileMapWidth}}",
    mobileMapHeight: "{{mobileMapHeight}}",
*/

export const HTML_TEMPLATE = `<div id="vizity-map"></div>
<script>
  window.vizityConf = {
    mapId: {{mapId}},
    lang: "{{lang}}",
    key: "{{key}}"{{urlBlock}}
  };
</script>
<script src="{{frontUrl}}assets/vizity.js"></script>
`;
export const URL_BLOCK_TEMPLATE = `,
    urlApi: "{{urlApi}}",
    urlStatic: "{{urlStatic}}"`;

export const DEFAULT_URl_STATIC = '/static/';

export const USER_STATUS = {
  0: 'INACTIVE',
  1: 'USER',
  2: 'SHARED_ACCOUNT',
  3: 'PROGRAMMATIC_ACCESS'
};

export const IMG_EDIT_DEFAULT = {
  resize: { width: 400, height: 260, fit: 'inside', withoutEnlargement: true }
};
// ---------------------- DEFAULT MAP COORDINATES ------------------------
export const COORDINATES_PARIS_CENTER = {
  longitude: 2.3488,
  latitude: 48.8534
};

export const DEFAULT_MAP_ZOOM = 12;

export const BOUNDS_FRANCE_CENTER = [-10.120760430793037, 41.78664464311814, 17.10542270927229, 51.13086894619947];

// ---------------------- DEFAULT MAPBOX STYLES --------------------------
export const DEFAULT_MAP_STYLES = [
  { name: 'Streets', value: 'mapbox://styles/lycaonsocial/ckwyz91mc0s1615s9hak6kms8' },
  { name: 'Navigation Jour', value: 'mapbox://styles/lycaonsocial/ckwyzfg3a0jei14pcetg64ut3' },
  { name: 'Navigation Nuit', value: 'mapbox://styles/lycaonsocial/ckwyzaxxd0s7h14o4htmq7jtc' },
  { name: 'Satellite Streets', value: 'mapbox://styles/lycaonsocial/ckwyzjksk0s8q15k5j3pj7s99' }
];

export const VIZITY_MAP_STYLES = [
  {
    name: 'Basique',
    variations: [
      {
        name: 'Base',
        value: 'base',
        color: 'default',
        buttonBackgroundColor: '#fff6d9',
        style: 'mapbox://styles/lycaonsocial/ckvb0x1se0qk818rsvv1ocl0g'
      },
      {
        name: 'Glacée',
        value: 'chilled',
        color: 'secondary',
        buttonBackgroundColor: '#6fcdf2',
        style: 'mapbox://styles/lycaonsocial/ckvb0zihy4s4415t5hak36dun'
      },
      {
        name: 'Galaxie',
        value: 'galaxy',
        color: 'secondary',
        buttonBackgroundColor: '#042633',
        style: 'mapbox://styles/lycaonsocial/ckvb10yzf0uaq15o26wlbjh4l'
      },
      {
        name: 'Sombre',
        value: 'overcast',
        color: 'default',
        buttonBackgroundColor: '#d4d4d4',
        style: 'mapbox://styles/lycaonsocial/ckvb12a9i0qpb18rsoea1196f'
      },
      {
        name: 'Plage',
        value: 'seashore',
        color: 'default',

        buttonBackgroundColor: '#ffeeba',
        style: 'mapbox://styles/lycaonsocial/ckvb15kc04sa015t579zae3so'
      },
      {
        name: 'Printemps',
        value: 'spring',
        color: 'default',
        buttonBackgroundColor: '#ffffff',
        style: 'mapbox://styles/lycaonsocial/ckvb17dba0wvy14nnylce2vv9'
      }
    ]
  },
  {
    name: 'Monochrome',
    variations: [
      {
        name: 'Ciel',
        value: 'sky',
        color: 'default',
        buttonBackgroundColor: '#d9f0ff',
        style: 'mapbox://styles/lycaonsocial/ckvb188fu0wrl15mtpsx3ocnb'
      },
      {
        name: 'Foncé',
        value: 'dark',
        color: 'secondary',
        buttonBackgroundColor: '#323436',
        style: 'mapbox://styles/lycaonsocial/ckvb195y99bhm15mpgz707cw6'
      },
      {
        name: 'Léger',
        value: 'light',
        color: 'default',
        buttonBackgroundColor: '#e3e3e3',
        style: 'mapbox://styles/lycaonsocial/ckvb1a33h0wyj14nnt4ca6toz'
      },
      {
        name: 'Chewing-gum',
        value: 'bubblegum ',
        color: 'default',
        buttonBackgroundColor: '#f08b8b',
        style: 'mapbox://styles/lycaonsocial/ckvb1b1q40uk315o2keov4agj'
      },
      {
        name: 'Minuit',
        value: 'midnight',
        color: 'secondary',
        buttonBackgroundColor: '#06007d',
        style: 'mapbox://styles/lycaonsocial/ckvb1c1au11c414qi115qzrz3'
      },
      {
        name: 'Doré',
        value: 'golden',
        color: 'default',
        buttonBackgroundColor: '#ffd000',
        style: 'mapbox://styles/lycaonsocial/ckvb1cngd0ww115mtr1aihvu9'
      }
    ]
  }
];

// -------------------------------- MARKERS LIBRARY --------------------------------

export const VIZITY_MARKERS_FIRST_ID = 1058;
export const VIZITY_MARKERS_LAST_ID = 1393;

// ------------------------------------ DEFAULT SETTINGS -------------------------------

export const DEFAULT_SETTINGS_BBOX = {
  zoom_init: DEFAULT_MAP_ZOOM,
  center_init_lat: COORDINATES_PARIS_CENTER.latitude,
  center_init_lng: COORDINATES_PARIS_CENTER.longitude,
  init_bounds: BOUNDS_FRANCE_CENTER
};

export const DEFAULT_SETTINGS_MAPBOX = {
  style: DEFAULT_MAP_STYLES[0].value,
  pitch: 0
};

export const DEFAULT_SETTINGS_MAP_CONTROLS = [];

export const DEFAULT_SLUGIFY_CONF = {
  replacement: '_', // replace spaces with replacement character, defaults to `-`
  remove: undefined, // remove characters that match regex, defaults to `undefined`
  lower: true, // convert to lower case, defaults to `false`
  strict: true, // strip special characters except replacement, defaults to `false`
  locale: 'fr', // language code of the locale to use
  trim: true // trim leading and trailing replacement chars, defaults to `true`
};

export const MAX_WALING_ROUTE_POI = 50;

export const DAYS_OF_TRIAL = 30;

export const MAP_FACTORY_SUBSCRIPTION = {
  STARTER_2022: {
    name: 'Starter',
    active: true,
    maps: 1,
    google_api_calls: 30,
    order: 1,
    row_number: 500,
    nb_days: 30
  },
  BUSINESS_2022: {
    name: 'Business',
    active: true,
    maps: 5,
    google_api_calls: 50,
    order: 2,
    row_number: 1500,
    nb_days: 30
  },
  PREMIUM_2022: {
    name: 'Premium',
    active: true,
    maps: 15,
    google_api_calls: 100,
    order: 3,
    row_number: 3000,
    nb_days: 30
  },
  CUSTOM_2022: {
    name: 'Custom',
    active: true,
    maps: 10000,
    google_api_calls: 10000,
    order: 4,
    row_number: 5000,
    nb_days: 30
  }
};

//export const MAP_FACTORY_AUTHORIZED_ACTIVE_SUBSCRIPTION = ['STARTER_2022', 'BUSINESS_2022', 'PREMIUM_2022', 'CUSTOM_2022'];

export const MAP_FACTORY_AUTHORIZED_ACTIVE_SUBSCRIPTION = Object.keys(MAP_FACTORY_SUBSCRIPTION);
