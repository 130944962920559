import React, { useState, useEffect } from 'react';
import axios from 'src/utils/axiosVizity';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Button, Grid, Typography, makeStyles, Checkbox, FormControlLabel, CardMedia, Collapse } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';

const MAP_TYPE = {
  classique: {
    name: 'CLASSIQUE',
    img: 'illstration_classique.svg',
    txt: 'CLASSIQUE_TEXT',
    url: '/vizity/mf/maps/places'
  },
  google_sheets: {
    name: 'GOOGLE_SHEETS',
    img: 'illustration_googlesheets.svg',
    txt: 'GOOGLE_SHEETS_TEXT',
    url: '/vizity/mf/maps/gs'
  },
  parcours: {
    name: 'WALKING_ROUTES',
    img: 'illustration_parcours.svg',
    txt: 'WALKING_ROUTES_TEXT',
    url: '/vizity/mf/maps/walkingRoutes'
  },
  multi_parcours: {
    name: 'MULTI_WALKING_ROUTES',
    img: 'illustration_multiparcours.svg',
    txt: 'MULTI_WALKING_ROUTES_TEXT',
    url: '/vizity/mf/maps/multiWalkingRoutes'
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark + '80',
    paddingTop: 20,
    outline: 'none',
    position: 'fixed',
    zIndex: 2000,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 40
  },
  mainContainer: {
    maxWidth: '700px'
  },
  card: {
    overflowY: 'auto'
  },
  img: {
    width: '100%',
    background: 'white'
  },
  mainTitle: {
    padding: '25px'
  },
  secondaryTitle: {
    padding: '0px 25px 25px',
    fontWeight: 500
  },
  simpleText1: {
    padding: '0px 25px 20px'
  },
  simpleText2: {
    padding: '0px 25px 20px',
    display: 'flex',
    alignItems: 'center'
  },
  colorText: {
    padding: '0px 25px'
  },
  img2: {
    width: '50%',
    paddingRight: '10%'
  },
  button: {
    margin: 'auto'
  },
  button2: {
    margin: 'auto',
    marginRight: '10px'
  },
  cardContent: {
    padding: '0px'
  }
}));

const DisclaimerView = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const intl = useIntl();
  const [read, setRead] = useState(false);
  const [open, setOpen] = useState(true);

  const handleRead = () => {
    setRead(!read);
  };

  const [openVideo, setOpenVideo] = useState(false);

  const handleOpenVideo = () => {
    setOpenVideo(!openVideo);
  };

  const handleAccept = async () => {
    if (read) {
      await axios.get('/api/account/valid-disclaimer?id=' + user['currentOrganizationId']);
    }
    setOpen(false);
  };

  const handleType = href => {
    setOpen(false);
  };

  const getLabel = name => {
    switch (name) {
      case 'CLASSIQUE':
        return intl.formatMessage({ id: 'dashboard.classic.name', defaultMessage: 'CLASSIC' });
      case 'CLASSIQUE_TEXT':
        return intl.formatMessage({ id: 'dashboard.classic.text', defaultMessage: 'Displays a selection of locations that will be highlighted on the map.' });
      case 'GOOGLE_SHEETS':
        return intl.formatMessage({ id: 'dashboard.googleSheets.name', defaultMessage: 'GOOGLE SHEETS' });
      case 'GOOGLE_SHEETS_TEXT':
        return intl.formatMessage({ id: 'dashboard.googleSheets.text', defaultMessage: 'Displays locations that can be filtered by category.' });
      case 'WALKING_ROUTES':
        return intl.formatMessage({ id: 'dashboard.parcours.name', defaultMessage: 'WALKING ROUTES' });
      case 'WALKING_ROUTES_TEXT':
        return intl.formatMessage({ id: 'dashboard.parcours.text', defaultMessage: 'Displays a selection of location steps, linked by a path.' });
      case 'MULTI_WALKING_ROUTES':
        return intl.formatMessage({ id: 'dashboard.multiParcours.name', defaultMessage: 'MULTI-WALKING ROUTES' });
      case 'MULTI_WALKING_ROUTES_TEXT':
        return intl.formatMessage({ id: 'dashboard.multiParcours.text', defaultMessage: 'Displays multiple routes on one map.' });
      default:
        return name;
    }
  };

  useEffect(() => {
    if (!user) {
      setOpen(true);
    }
  }, [setOpen, user]);

  if (!open || !user || user.have_seen_disclaimer_message || (!user.has_a_valid_subscription && !user.during_test_period)) {
    return null;
  }

  return (
    <Page className={classes.root} title="Disclaimer">
      <Grid container spacing={0} direction={'column'} className={classes.mainContainer}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Grid container>
              <Grid item xs={12}>
                <img alt="TopImg" src="/static/images/vizity/welcome_page/image_bienvenue.png" className={classes.img} />
              </Grid>
              <Grid className={classes.colortext}>
                <Grid item xs={12}>
                  <Typography align="center" variant="h2" className={classes.mainTitle}>
                    <FormattedMessage color="textSecondary" id="disclaimer.help.title" defaultMessage="Welcome on the Map Factory" />
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.simpleText1}>
                    <FormattedMessage
                      color="textSecondary"
                      id="disclaimer.help.intro"
                      defaultMessage="We are very pleased to have you among our first users of this new solution allowing you to create interactive maps in complete autonomy"
                    />
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.secondaryTitle}>
                    <FormattedMessage
                      color="textSecondary"
                      id="disclaimer.help.video"
                      defaultMessage="Before you start you can watch this quick presentation video"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.simpleText2}>
                  {!openVideo && (
                    <>
                      <img alt="MiddleImg" src="/static/images/vizity/welcome_page/tutos.png" className={classes.img2} />
                      <Button className={classes.button} color="secondary" variant="contained" onClick={handleOpenVideo}>
                        <FormattedMessage color="textSecondary" id="disclaimer.seeVideo" defaultMessage="watch the video" />
                      </Button>
                    </>
                  )}
                  <Collapse in={openVideo} timeout="auto" unmountOnExit>
                    <iframe
                      width="570"
                      height="315"
                      src="https://www.youtube.com/embed/fFBEgClft3g"
                      title="video presentation"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.secondaryTitle}>
                    <FormattedMessage
                      color="textSecondary"
                      id="disclaimer.help.choose"
                      defaultMessage="To create an interactive map, simply choose your map type:"
                    />
                  </Typography>
                </Grid>

                {Object.entries(MAP_TYPE).map(([key, mapType]) => {
                  return (
                    <Grid key={key + 'title'} item xs={12} className={classes.simpleText2}>
                      <CardMedia
                        style={{
                          width: '20%',
                          borderRadius: '5px',
                          borderColor: '#AFAFAF',
                          height: '105px',
                          backgroundSize: '220px',
                          outline: '2px solid rgb(175, 175, 175)',
                          border: '5px solid white'
                        }}
                        image={'/static/images/vizity/welcome_page/' + mapType.img}
                      />

                      <Grid style={{ paddingLeft: '20px', width: '50%' }}>
                        <Typography style={{ fontWeight: 500, paddingBottom: '10px' }}>{getLabel(mapType.name)}</Typography>
                        <Typography>{getLabel(mapType.txt)}</Typography>
                      </Grid>
                      <Button
                        className={classes.button}
                        color="secondary"
                        variant="contained"
                        component={RouterLink}
                        to={mapType.url}
                        onClick={() => handleType(mapType.url)}
                      >
                        <FormattedMessage color="textSecondary" id="disclaimer.type.choose" defaultMessage="choose this type" />
                      </Button>
                    </Grid>
                  );
                })}
                <Grid item xs={12} style={{ float: 'right' }}>
                  <FormControlLabel
                    control={<Checkbox checked={read} onChange={() => handleRead()} key="acceptCheckbox" />}
                    label={intl.formatMessage({ id: 'disclaimer.neverShow', defaultMessage: "don't show me that message again" })}
                    labelPlacement="start"
                    style={{ margin: '0px' }}
                  ></FormControlLabel>

                  <Button className={classes.button2} color="secondary" variant="contained" onClick={handleAccept}>
                    <FormattedMessage color="textSecondary" id="disclaimer.ok" defaultMessage="ok" /> !
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Page>
  );
};

export default DisclaimerView;
