/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Chip, Drawer, Hidden, List, ListSubheader, makeStyles } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcons,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Map as MapIcon,
  FileText as FileTextIcon,
  Tool as ToolIcon,
  Grid as GridIcon,
  Clipboard as ClipboardIcon,
  Globe as GlobalIcon
} from 'react-feather';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import EmojiNatureIcon from '@material-ui/icons/EmojiNatureTwoTone';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import { injectIntl } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { ORGANIZATION_ID, PERMISSION_ID, OTCP_ORGANIZATION_IDS } from 'src/vizity/constantsVizity';
import { hasPermission } from 'src/utils/permissionUtils';
import _ from 'lodash';

const VIZITY_ADMIN = -99;
const VIZITY_MAP_FACTORY = -98;

const deviasSections = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      },
      {
        title: 'Dashboard Alternative',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative'
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers'
          },
          {
            title: 'View Customer',
            href: '/app/management/customers/1'
          },
          {
            title: 'Edit Customer',
            href: '/app/management/customers/1/edit'
          }
        ]
      },
      {
        title: 'Products',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'List Products',
            href: '/app/management/products'
          },
          {
            title: 'Create Product',
            href: '/app/management/products/create'
          }
        ]
      },
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/management/orders'
          },
          {
            title: 'View Order',
            href: '/app/management/orders/1'
          }
        ]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'List Invoices',
            href: '/app/management/invoices'
          },
          {
            title: 'View Invoice',
            href: '/app/management/invoices/1'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Applications',
    items: [
      {
        title: 'Projects Platform',
        href: '/app/projects',
        icon: BriefcaseIcon,
        items: [
          {
            title: 'Overview',
            href: '/app/projects/overview'
          },
          {
            title: 'Browse Projects',
            href: '/app/projects/browse'
          },
          {
            title: 'Create Project',
            href: '/app/projects/create'
          },
          {
            title: 'View Project',
            href: '/app/projects/1'
          }
        ]
      },
      {
        title: 'Social Platform',
        href: '/app/social',
        icon: ShareIcon,
        items: [
          {
            title: 'Profile',
            href: '/app/social/profile'
          },
          {
            title: 'Feed',
            href: '/app/social/feed'
          }
        ]
      },
      {
        title: 'Kanban',
        href: '/app/kanban',
        icon: TrelloIcon
      },
      {
        title: 'Mail',
        href: '/app/mail',
        icon: MailIcon
      },
      {
        title: 'Chat',
        href: '/app/chat',
        icon: MessageCircleIcon,
        info: () => <Chip color="secondary" size="small" label="Updated" />
      },
      {
        title: 'Calendar',
        href: '/app/calendar',
        icon: CalendarIcon,
        info: () => <Chip color="secondary" size="small" label="Updated" />
      }
    ]
  },
  {
    subheader: 'Auth',
    items: [
      {
        title: 'Login',
        href: '/login-unprotected',
        icon: LockIcon
      },
      {
        title: 'Register',
        href: '/register-unprotected',
        icon: UserPlusIcon
      }
    ]
  },
  {
    subheader: 'Pages',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Error',
        href: '/404',
        icon: AlertCircleIcon
      },
      {
        title: 'Pricing',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
  {
    subheader: 'Extra',
    items: [
      {
        title: 'Charts',
        href: '/app/extra/charts',
        icon: BarChartIcon,
        items: [
          {
            title: 'Apex Charts',
            href: '/app/extra/charts/apex'
          }
        ]
      },
      {
        title: 'Forms',
        href: '/app/extra/forms',
        icon: EditIcon,
        items: [
          {
            title: 'Formik',
            href: '/app/extra/forms/formik'
          },
          {
            title: 'Redux Forms',
            href: '/app/extra/forms/redux'
          }
        ]
      },
      {
        title: 'Editors',
        href: '/app/extra/editors',
        icon: LayoutIcon,
        items: [
          {
            title: 'DraftJS Editor',
            href: '/app/extra/editors/draft-js'
          },
          {
            title: 'Quill Editor',
            href: '/app/extra/editors/quill'
          }
        ]
      }
    ]
  }
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const vizitySuperAdmin = [
  {
    id: 'menu.admin',
    items: [
      {
        id: 'menu.admin.create.organization',
        icon: UsersIcons,
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        href: '/vizity/admin/create-organization'
      },
      {
        id: 'menu.admin.create.user',
        icon: UserPlusIcon,
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        href: '/vizity/admin/create-user'
      },
      {
        id: 'menu.admin.add.image',
        icon: AddPhotoAlternateOutlinedIcon,
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        href: '/vizity/admin/add-image'
      },
      {
        id: 'menu.admin.permission.management',
        icon: ClipboardIcon,
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        items: [
          {
            icon: GlobalIcon,
            id: 'menu.admin.permission.management.global',
            href: '/vizity/admin/permissions-management/global-permissions'
          },
          {
            icon: BriefcaseIcon,
            id: 'menu.admin.permission.management.organization',
            href: '/vizity/admin/permissions-management/organization-permissions'
          },
          {
            icon: MapIcon,
            id: 'menu.admin.permission.management.map',
            href: '/vizity/admin/permissions-management/map-permissions'
          },
          {
            icon: LayoutIcon,
            id: 'menu.admin.permission.management.sheet',
            href: '/vizity/admin/permissions-management/sheet-permissions'
          }
        ]
      },
      {
        id: 'menu.apidae',
        icon: EmojiNatureIcon,
        href: '/vizity/admin/apidae',
        permissionId: PERMISSION_ID.VIZITY_ADMIN
      },
      {
        id: 'menu.subscription',
        icon: DollarSignIcon,
        href: '/vizity/admin/subscription',
        permissionId: PERMISSION_ID.VIZITY_ADMIN
      },
      {
        id: 'menu.admin.gsheets.test',
        icon: LayoutIcon,
        href: '/vizity/admin/test',
        permissionId: PERMISSION_ID.VIZITY_ADMIN
      }
    ]
  }
];

const vizityOtcpMembers = [
  {
    id: 'menu.maps',
    items: [
      // -- Adhérents OTCP
      {
        id: 'otcp.members.maps.around.my.business',
        icon: MapIcon,
        href: '/vizity/otcpMembers/maps/aroundMyBusiness',
        permissionId: PERMISSION_ID.OTCP_MEMBERS_AROUND_MY_BUSINESS
      }
    ]
  }
];

const vizityOtcp = [
  {
    id: 'menu.maps',
    items: [
      {
        id: 'otcp.maps.places.title',
        icon: MapIcon,
        href: '/vizity/otcp/maps/places',
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS,
        organizationIds: OTCP_ORGANIZATION_IDS
      },
      {
        id: 'mf.maps.google.sheets.title',
        icon: MapIcon,
        href: '/vizity/otcp/maps/gs',
        permissionId: PERMISSION_ID.OTCP_FACTORY_GOOGLE_SHEETS_MAPS,
        organizationIds: OTCP_ORGANIZATION_IDS
      },
      {
        id: 'otcp.maps.walking.routes',
        icon: MapIcon,
        href: '/vizity/otcp/maps/walkingRoutes',
        permissionId: PERMISSION_ID.OTCP_WALKING_ROUTES_MAPS,
        organizationIds: OTCP_ORGANIZATION_IDS
      },
      {
        id: 'otcp.maps.multi.walking.routes',
        icon: MapIcon,
        href: '/vizity/otcp/maps/multiWalkingRoutes',
        permissionId: PERMISSION_ID.OTCP_MULTI_WALKING_ROUTES_MAPS,
        organizationIds: OTCP_ORGANIZATION_IDS
      }
    ]
  },
  {
    id: 'menu.data',
    items: [
      {
        id: 'otcp.data.unmissable.places.title',
        icon: FileTextIcon,
        href: '/vizity/otcp/data/unmissable/places',
        permissionId: PERMISSION_ID.OTCP_UNMISSABLE_PLACES,
        organizationIds: [ORGANIZATION_ID.OTCP_LOISIR]
      }
    ]
  },
  {
    id: 'menu.configuration',
    items: [
      {
        id: 'menu.configuration.default.settings',
        icon: ToolIcon,
        permissionId: PERMISSION_ID.OTCP_DEFAULT_SETTINGS,
        organizationIds: OTCP_ORGANIZATION_IDS,
        href: '/vizity/otcp/conf/default-settings'
      }
    ]
  }
];

const vizityMapFactory = [
  {
    id: 'menu.mainDashboard',
    items: [
      {
        id: 'menu.mainDashboard.dashboard',
        icon: GridIcon,
        href: '/vizity/mf/dashboard',
        permissionId: PERMISSION_ID.NO_RESTRICTION
      }
    ]
  },
  {
    id: 'menu.maps',
    items: [
      {
        id: 'mf.maps.google.sheets.title',
        icon: MapIcon,
        href: '/vizity/mf/maps/gs',
        permissionId: PERMISSION_ID.MAP_FACTORY_GOOGLE_SHEETS_MAPS
      },
      /* -------------------- */
      {
        id: 'otcp.maps.places.title',
        icon: MapIcon,
        href: '/vizity/mf/maps/places',
        permissionId: PERMISSION_ID.MAP_FACTORY_PLACES_MAPS
      },
      {
        id: 'otcp.maps.walking.routes',
        icon: MapIcon,
        href: '/vizity/mf/maps/walkingRoutes',
        permissionId: PERMISSION_ID.MAP_FACTORY_WALKING_ROUTES_MAPS
      },
      {
        id: 'otcp.maps.multi.walking.routes',
        icon: MapIcon,
        href: '/vizity/mf/maps/multiWalkingRoutes',
        permissionId: PERMISSION_ID.MAP_FACTORY_MULTI_WALKING_ROUTES_MAPS
      }
    ]
  },
  {
    id: 'menu.configuration',
    items: [
      {
        id: 'menu.configuration.default.settings',
        icon: ToolIcon,
        href: '/vizity/mf/conf/default-settings',
        permissionId: PERMISSION_ID.MAP_FACTORY_DEFAULT_SETTINGS
      } /* ,
      {
        id: 'menu.configuration.subscription',
        icon: DollarSignIcon,
        href: '/vizity/mf/conf/subscription',
        permissionId: PERMISSION_ID.NO_RESTRICTION
      } */
    ]
  } /* ,
  {
    id: 'menu.application',
    items: [
      {
        id: 'menu.application.formula',
        icon: DollarSignIcon,
        href: '/vizity/mf/formula',
        permissionId: PERMISSION_ID.NO_RESTRICTION
      },
      {
        id: 'menu.application.payment',
        icon: FileTextIcon,
        href: '/vizity/admin/subscription',
        permissionId: PERMISSION_ID.NO_RESTRICTION
      }
    ]
  } */
];

function initMenu(intl, organizationId, isMapFactoryOrganization, isOtcpMember) {
  let map = new Map();
  let sections = [];

  if (isMapFactoryOrganization) {
    map.set('menu.mainDashboard', intl.formatMessage({ id: 'menu.mainDashboard', defaultMessage: 'Main dashboard' }));
    map.set('menu.mainDashboard.dashboard', intl.formatMessage({ id: 'menu.mainDashboard.dashboard', defaultMessage: 'dashboard' }));
    map.set('menu.maps', intl.formatMessage({ id: 'menu.maps', defaultMessage: 'Maps management' }));
    // --
    map.set('mf.maps.google.sheets.title', intl.formatMessage({ id: 'mf.maps.google.sheets.title', defaultMessage: 'Google Sheets maps' }));
    map.set('otcp.maps.places.title', intl.formatMessage({ id: 'otcp.maps.places.title', defaultMessage: 'Selection maps' }));
    map.set('otcp.maps.walking.routes', intl.formatMessage({ id: 'otcp.maps.walking.routes', defaultMessage: 'Walking routes maps' }));
    map.set('otcp.maps.multi.walking.routes', intl.formatMessage({ id: 'otcp.maps.multi.walking.routes', defaultMessage: 'Multi-walking routes maps' }));
    // ------------
    map.set('menu.data', intl.formatMessage({ id: 'menu.data', defaultMessage: 'Data management' }));
    // --
    map.set('otcp.data.unmissable.places.title', intl.formatMessage({ id: 'otcp.data.unmissable.places.title', defaultMessage: 'Unmissable places' }));
    map.set('menu.configuration', intl.formatMessage({ id: 'menu.configuration', defaultMessage: 'Configuration' }));
    map.set('menu.configuration.default.settings', intl.formatMessage({ id: 'menu.configuration.default.settings', defaultMessage: 'Default settings' }));
    map.set('menu.configuration.subscription', intl.formatMessage({ id: 'menu.configuration.subscription', defaultMessage: 'Subscription' }));

    map.set('menu.application', intl.formatMessage({ id: 'menu.application', defaultMessage: 'Pricing' }));
    map.set('menu.application.formula', intl.formatMessage({ id: 'menu.application.formula', defaultMessage: 'Formula' }));
    map.set('menu.application.payment', intl.formatMessage({ id: 'menu.application.payment', defaultMessage: 'Payment' }));

    sections = vizityMapFactory.concat(sections);
  } else if (isOtcpMember) {
    map.set('menu.maps', intl.formatMessage({ id: 'menu.maps', defaultMessage: 'Maps management' }));
    // --
    map.set('otcp.members.maps.around.my.business', intl.formatMessage({ id: 'otcp.members.maps.around.my.business', defaultMessage: 'Around my business' }));

    sections = vizityOtcpMembers.concat(sections);
  } else {
    switch (organizationId) {
      case VIZITY_ADMIN:
        map.set('menu.admin', intl.formatMessage({ id: 'menu.admin', defaultMessage: 'Administrator' }));
        map.set('menu.admin.create.organization', intl.formatMessage({ id: 'menu.admin.create.organization', defaultMessage: 'Create Organization' }));
        map.set('menu.admin.create.user', intl.formatMessage({ id: 'menu.admin.create.user', defaultMessage: 'Create User' }));
        map.set('menu.admin.add.image', intl.formatMessage({ id: 'menu.admin.add.image', defaultMessage: 'Add image' }));
        map.set('menu.admin.gsheets.test', intl.formatMessage({ id: 'menu.admin.gsheets.test', defaultMessage: 'Test GSheets' }));
        map.set('menu.apidae', intl.formatMessage({ id: 'menu.apidae', defaultMessage: 'Apidae' }));
        map.set('menu.subscription', intl.formatMessage({ id: 'menu.subscription', defaultMessage: 'Subscription' }));

        map.set('menu.admin.permission.management', intl.formatMessage({ id: 'menu.admin.permission.management', defaultMessage: 'Permission management' }));
        map.set(
          'menu.admin.permission.management.global',
          intl.formatMessage({ id: 'menu.admin.permission.management.global', defaultMessage: 'Global permission' })
        );
        map.set(
          'menu.admin.permission.management.organization',
          intl.formatMessage({ id: 'menu.admin.permission.management.organization', defaultMessage: 'Organizations permissions' })
        );
        map.set('menu.admin.permission.management.map', intl.formatMessage({ id: 'menu.admin.permission.management.map', defaultMessage: 'Maps permissions' }));
        map.set(
          'menu.admin.permission.management.sheet',
          intl.formatMessage({ id: 'menu.admin.permission.management.sheet', defaultMessage: 'Sheets permissions' })
        );

        sections = vizitySuperAdmin.concat(sections);

        break;

      case ORGANIZATION_ID.OTCP_CONGRES:
      case ORGANIZATION_ID.OTCP_LOISIR:
      case ORGANIZATION_ID.OTCP_PARTENAIRE_1:
      case ORGANIZATION_ID.OTCP_PARTENAIRE_2:
      case ORGANIZATION_ID.OTCP_PARTENAIRE_3:
      case ORGANIZATION_ID.OTCP_PARTENAIRE_4:
      case ORGANIZATION_ID.OTCP_PARTENAIRE_5:
        map.set('menu.maps', intl.formatMessage({ id: 'menu.maps', defaultMessage: 'Maps management' }));
        // --
        map.set('mf.maps.google.sheets.title', intl.formatMessage({ id: 'mf.maps.google.sheets.title', defaultMessage: 'Google Sheets maps' }));
        // --
        map.set('otcp.maps.places.title', intl.formatMessage({ id: 'otcp.maps.places.title', defaultMessage: 'Selection maps' }));
        map.set('otcp.maps.walking.routes', intl.formatMessage({ id: 'otcp.maps.walking.routes', defaultMessage: 'Walking routes maps' }));
        map.set('otcp.maps.multi.walking.routes', intl.formatMessage({ id: 'otcp.maps.multi.walking.routes', defaultMessage: 'Multi-walking routes maps' }));
        // ------------
        map.set('menu.data', intl.formatMessage({ id: 'menu.data', defaultMessage: 'Data management' }));
        // --
        map.set('otcp.data.unmissable.places.title', intl.formatMessage({ id: 'otcp.data.unmissable.places.title', defaultMessage: 'Unmissable places' }));
        map.set('menu.configuration', intl.formatMessage({ id: 'menu.configuration', defaultMessage: 'Configuration' }));
        map.set('menu.configuration.default.settings', intl.formatMessage({ id: 'menu.configuration.default.settings', defaultMessage: 'Default settings' }));

        sections = vizityOtcp.concat(sections);

        break;

      case ORGANIZATION_ID.VIZITY:
        break;

      case ORGANIZATION_ID.VIZITY_DEMO:
      case VIZITY_MAP_FACTORY:
      default:
        map.set('menu.mainDashboard', intl.formatMessage({ id: 'menu.mainDashboard', defaultMessage: 'Main dashboard' }));
        map.set('menu.mainDashboard.dashboard', intl.formatMessage({ id: 'menu.mainDashboard.dashboard', defaultMessage: 'dashboard' }));
        //--
        map.set('menu.maps', intl.formatMessage({ id: 'menu.maps', defaultMessage: 'Maps management' }));
        // --
        map.set('mf.maps.google.sheets.title', intl.formatMessage({ id: 'mf.maps.google.sheets.title', defaultMessage: 'Google Sheets maps' }));
        map.set('otcp.maps.places.title', intl.formatMessage({ id: 'otcp.maps.places.title', defaultMessage: 'Selection maps' }));
        map.set('otcp.maps.walking.routes', intl.formatMessage({ id: 'otcp.maps.walking.routes', defaultMessage: 'Walking routes maps' }));
        map.set('otcp.maps.multi.walking.routes', intl.formatMessage({ id: 'otcp.maps.multi.walking.routes', defaultMessage: 'Multi-walking routes maps' }));
        // ------------
        map.set('menu.data', intl.formatMessage({ id: 'menu.data', defaultMessage: 'Data management' }));
        // --
        map.set('otcp.data.unmissable.places.title', intl.formatMessage({ id: 'otcp.data.unmissable.places.title', defaultMessage: 'Unmissable places' }));
        map.set('menu.configuration', intl.formatMessage({ id: 'menu.configuration', defaultMessage: 'Configuration' }));
        map.set('menu.configuration.default.settings', intl.formatMessage({ id: 'menu.configuration.default.settings', defaultMessage: 'Default settings' }));
        map.set('menu.configuration.subscription', intl.formatMessage({ id: 'menu.configuration.subscription', defaultMessage: 'Subscription' }));

        map.set('menu.application', intl.formatMessage({ id: 'menu.application', defaultMessage: 'Pricing' }));
        map.set('menu.application.formula', intl.formatMessage({ id: 'menu.application.formula', defaultMessage: 'Formula' }));
        map.set('menu.application.payment', intl.formatMessage({ id: 'menu.application.payment', defaultMessage: 'Payment' }));

        sections = vizityMapFactory.concat(sections);

        break;

      //console.warn('Unknown organization ' + organizationId);
    }
  }

  return [map, sections];
}

const NavBar = ({ onMobileClose, openMobile, intl }) => {
  const classes = useStyles();
  const location = useLocation();
  const { settings } = useSettings();
  const { user } = useAuth();

  const initMenuSections = useCallback(() => {
    let [map, sections] = initMenu(intl, user.currentOrganizationId, user.isMapFactoryOrganization, user.isOtcpMember);

    if (user.admin === true) {
      let [adminMap, adminSections] = initMenu(intl, VIZITY_ADMIN);
      map = new Map([...map].concat([...adminMap]));
      sections = sections.concat(adminSections);
    }

    let currentSections = [];

    for (const section of _.cloneDeep(sections)) {
      let currentItem = [];

      for (const item of section.items) {
        if (
          hasPermission(user, item.permissionId) &&
          (user.isMapFactoryOrganization === true || !('organizationIds' in item) || item.organizationIds.includes(user.currentOrganizationId))
        ) {
          if (item['items']) {
            for (const val of item['items']) {
              val['title'] = map.get(val.id);
            }
          }
          item['title'] = map.get(item.id);
          currentItem.push(item);
        }
      }

      if (currentItem.length > 0) {
        let currentSection = _.cloneDeep(section);

        currentSection['subheader'] = map.get(section.id);
        currentSection.items = currentItem;

        currentSections.push(currentSection);
      }
    }

    return settings.displayDeviasMenu && user.admin === true ? currentSections.concat(deviasSections) : currentSections;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, settings.displayDeviasMenu /* user.currentOrganizationId, */, user]);

  const [menu_sections, setMenuSection] = useState(() => initMenuSections());

  useEffect(() => {
    setMenuSection(initMenuSections());
  }, [initMenuSections]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        {/* 
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Your tier:
              {' '}
              <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        */}
        <Box p={2}>
          {menu_sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        {/* 
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link variant="subtitle1" color="secondary" component={RouterLink} to="/docs">
              Check our docs
            </Link>
          </Box>
        </Box>
        */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default injectIntl(NavBar);
