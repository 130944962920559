import {
  ORGANIZATION_ID,
  TYPOLOGY_ID,
  MAP_OPTIONS_CSS,
  MAP_OPTIONS_MAPBOX_STYLE,
  MAP_OPTIONS_MAPBOX_STYLE_GREY,
  MAP_OPTIONS_STYLES_MAP,
  SHEET_ID,
  LAYER_ID,
  PERMISSION_MAP
} from 'src/vizity/constantsVizity';

export const OTCP_API_CALLS_MAX = 1000;
export const OTCP_GOOGLE_SHEETS_MAPS_MAX = 10000;

export const OTCP_CAT_ID = [
  { id: 3, key: 'partners.otcp.data.category.cultural' },
  { id: 4, key: 'partners.otcp.data.category.restaurants' },
  { id: 5, key: 'partners.otcp.data.category.shopping' },
  { id: 2, key: 'partners.otcp.data.category.accommodation' },
  { id: 7, key: 'partners.otcp.data.category.food' }, // Alimentation
  { id: 1, key: 'partners.otcp.data.category.diverse' } //Autres pros
  // 6 : 'Visites guidées et agences'  see VIZ-743
];

export const OTCP_POI_TYPE_ID = {
  POI: 1,
  LANDMARK: 2
};

export const OTCP_PIN_MAP_PLACES_ID = {
  // 1: 904, // OLD Pin event / stade => 915
  // 2: 905, // OLD Pin selection => 914

  1: 906, //pin_stade.png
  2: 907, //pin_festivites.png
  3: 908, //pin_jeux-olympiques.png
  4: 909, //pin_exposition.png
  5: 910, //pin_noel.png
  6: 911, //pin_gratuit.png
  7: 912, //pin_paques.png
  8: 913, //pin_saint-valentin.png
  9: 914, //pin_selection.png
  10: 915 //pin_sport.png
};

export const OTCP_PIN_UNMISSABLE_PLACES_ID = {
  1: 976, //	Picto_repere_Arc_de_triomphe_sans-fond.png
  2: 977, //	Picto_repere_Basilique_Sacre-Coeur_sans-fond.png
  3: 978, //	Picto_repere_Catacombes_sans-fond.png
  4: 979, //	Picto_repere_Cite_des_sciences_sans-fond.png
  5: 980, //	Picto_repere_Disney_sans-fond.png
  6: 981, //	Picto_repere_Centre_Pompidou_sans-fond.png
  7: 982, //	Picto_repere_Grand_Palais_sans-fond.png
  8: 983, //	Picto_repere_Hotel_de_Ville_sans-fond.png
  9: 984, //	Picto_repere_La_Defense_sans-fond.png
  10: 985, //	Picto_repere_Louis_Vuitton_sans-fond.png
  11: 986, //	Picto_repere_MNHN_sans-fond.png
  12: 987, //	Picto_repere_Musee_du_Louvre_sans-fond.png
  13: 988, //	Picto_repere_Musee_Orsay_sans-fond.png
  14: 989, //	Picto_repere_Place_Bastille_sans-fond.png
  15: 990, //	Picto_repere_Palais_Garnier_sans-fond.png
  16: 991, //	Picto_repere_Saint-Denis_sans-fond.png
  17: 992, //	Picto_repere_Tour_Eiffel_sans-fond.png
  18: 993, //	Picto_repere_Tour_Montparnasse_sans-fond.png
  19: 994, //	Picto_repere_Cimetiere_Pere_Lachaise_sans-fond.png
  20: 995, //	Picto_repere_Cathedrale_Notre-Dame_sans-fond.png
  21: 1017 // Picto_repere_Versailles_sans-fond.png
};

//Array.from(Array(20).keys()).forEach(val => console.log(val + ':' + (759 + val) + ','));

export const OTCP_PIN_STEP_ID = {
  1: 760,
  2: 761,
  3: 762,
  4: 763,
  5: 764,
  6: 765,
  7: 766,
  8: 767,
  9: 768,
  10: 769,
  11: 770,
  12: 771,
  13: 772,
  14: 773,
  15: 774,
  16: 775,
  17: 776,
  18: 777,
  19: 778,
  20: 779
};

export const OTCP_PIN_LANDMARK = {
  1: 1020,
  2: 1021,
  3: 1022,
  4: 1023,
  5: 1024,
  6: 1025,
  7: 1026,
  8: 1027,
  9: 1028,
  10: 1029,
  11: 1030,
  12: 1031,
  13: 1032,
  14: 1033,
  15: 1034,
  16: 1035,
  17: 1036,
  18: 1037,
  19: 1038,
  20: 1039
};

export const OTCP_SUPPORTED_LOCALES = ['fr', 'en', 'es', 'de'];

export const OTCP_DEFAULT_LOCALE = 'fr';

export const OTCP_WALKING_ROUTE_STEP_TYPE = {
  STEP_POINT: 1,
  CROSSING_POINT: 2,
  POI_WITHOUT_STEP: 3
};

export const OTCP_CAT_TYPES = [
  {
    id: 1,
    vals: [
      { 101001: 'Administration et assimilées' },
      { 101002: "Agences d'hôtesses" },
      { 101003: 'Assistance médicale' },
      { 101004: 'Association / fédération du tourisme' },
      { 101005: 'Association / fédération hors tourisme' },
      { 101006: 'Autres' },
      { 101007: 'Badges / badges multiservices' },
      { 101008: 'Bureaux de change' },
      { 101009: 'Centres de congrès' },
      { 101010: 'Commerces ouverts tard ou toute la nuit' },
      { 101011: 'Commissariats' },
      { 101012: 'Communication (E-communication/édition/télécommunications…)' },
      { 101013: 'Concepteur de stand' },
      { 101014: 'Décoration florale' },
      { 101015: 'Détaxe' },
      { 101016: 'Écoles de cuisine' },
      { 101017: 'Écoles de danse' },
      { 101018: 'Écoles de langues et assimilés' },
      { 101019: 'Hôpitaux' },
      { 101020: 'Lieux événementiels - Salles de réception' },
      { 101021: 'Lieux événementiels - Salles de réunion' },
      { 101022: 'Lieux événementiels insolites' },
      { 101023: 'Location de matériel' },
      { 101024: 'Logistique (transport / livraison / stockage / manutention…)' },
      { 101025: 'Pharmacies ouvertes tard ou toute la nuit' },
      { 101026: 'Prestataires de service' },
      { 101027: 'Prestation technique (sons, lumières)' },
      { 101028: 'Produit dérivés / cadeaux' },
      { 101029: 'Recherche speaker/conférencier' },
      { 101030: 'Sociétés de récupération tva - fiscalité' },
      { 101031: 'Syndicats professionnels' },
      { 101032: 'Toilettes' },
      { 101033: 'Traduction et interprétariat' },
      { 101034: 'Vidéo / films / photos' },
      //###### CCI ######
      { 103001: 'Opticien' },
      { 103002: 'Coiffure' },
      { 103003: 'Pharmacie' },
      { 103004: 'Tatouage' },
      { 103005: 'Transports touristiques' },
      { 103006: 'Agence immobilière/ Conciergerie' },
      { 103007: 'Service financier' },
      { 103008: 'Voyagiste/Guides' },
      { 103009: 'Divers' },
      { 103010: 'Office de tourisme}' }
    ]
  },
  {
    id: 2,
    vals: [
      { 201001: 'Hôtels' },
      { 201002: 'Résidences apparthotels' },
      { 201003: 'Camping / Hôtellerie de plein air' }, // # Update
      { 201004: 'Appartements meublés' }, //# Update
      { 201005: "Chambres d'hôtes" },
      { 201006: 'Pour les jeunes (auberges de jeunesses / hébergements coll)' }, //# Update
      { 201007: 'Hébergements insolites' },
      { 201008: 'Centrales de réservations' },
      //###### CCI ######
      { 203001: 'Hotelerie' },
      { 203002: 'Airbnb' }
    ]
  },
  {
    id: 3,
    vals: [
      { 301001: 'Bibliothèque' },
      { 301002: 'Boutique-magasin' },
      { 301003: 'Cabaret' },
      { 301004: "Lieu d'expositions et de salons" },
      { 301005: 'Château' },
      { 301006: 'Cinéma' },
      { 301007: 'Cirque' },
      { 301008: 'Discothèque et club' },
      { 301009: 'Eglise et autre lieu de culte' },
      { 301010: 'Musée' },
      { 301011: 'Monument' },
      { 301012: 'Parc de loisirs' },
      { 301013: 'Parc-jardin-promenade' },
      { 301014: 'Piscine' },
      { 301015: 'Salle de spectacle' },
      { 301016: 'Jeu et détente' },
      { 301017: 'Stade' },
      { 301018: 'Zoo & aquarium' },
      //###### CCI ######
      { 303001: "Galerie d'art" }
    ]
  },
  {
    id: 4,
    vals: [
      { 401001: 'Bar' },
      { 401002: 'Bar à vins &    bar à champagnes' }, // # Update
      { 401003: 'Bistrot' },
      { 401004: 'Brasserie' },
      { 401006: 'Café' },
      { 401007: "Restaurant original par le lieu ou l'animation" }, // # Update
      { 401008: 'Glacier' },
      { 401009: 'Pub' },
      { 401040: 'Restaurant classique' },
      { 401011: 'Restaurant gastronomique' },
      { 401012: 'Restauration rapide & self services' }, // # Update
      { 401013: 'Salon de thé' },
      { 401014: 'Autres' },
      { 401015: 'Tables communes' },
      { 401016: 'Centrales de réservations' },
      { 401017: 'Traiteurs' },
      //# DAE ######
      { 402001: 'Gastronomie / Bistronomie' },
      //###### CCI ######
      { 403001: 'Restauration' },
      { 403002: 'Café-Restaurants' }
    ]
  },
  {
    id: 5,
    vals: [
      { 501001: 'Antiquités' },
      { 501002: 'Accessoires' },
      { 501003: 'Art de vivre' },
      { 501006: 'Bijoux - joaillerie' }, // # Update
      { 501007: 'Centres commerciaux' },
      { 501008: 'Chapeaux' },
      { 501009: 'Chaussures' },
      { 501010: 'Concept stores - multimarques' }, // # Update
      { 501011: 'Cosmétique - parfums' }, // # Update
      { 501012: 'Epicerie fine et gastronomie (traiteurs - confiseurs - etc.)' }, // # Update
      { 501013: "Galeries d'art et artisanat d'art" },
      { 501014: 'Grands magasins' },
      { 501015: 'Haute couture - luxe' }, // # Update
      { 501016: 'Librairies et multimedia (CD - matériel photo ou informatique)' }, // # Update
      { 501017: 'Lingerie' },
      { 501018: 'Marchés alimentaires' },
      { 501019: 'Marchés spécialisés' },
      { 501020: 'Maroquinerie et autres sacs' },
      { 501021: 'Mode enfant' },
      { 501022: 'Mode femme' },
      { 501023: 'Mode homme' },
      { 501024: 'Mode sport' },
      { 501025: 'Souvenirs/jouets' },
      { 501026: 'Stocks' },
      { 501027: 'Vins & alcools' },
      //###### DAE ######
      { 502001: 'Mode accessoires' }, // # Mode_accessoires
      { 502002: 'Produits manufactures' }, // # Produits_manufactures
      { 502003: 'Univers maison' }, // # Univers_maison
      //###### CCI ######
      { 503001: 'Antiquaire' },
      { 503002: 'Tapis / Rideaux' },
      { 503003: 'Maison/Déco' },
      { 503004: 'Cadeaux/Souvenirs' },
      { 503005: 'Mode' },
      { 503006: 'Maroquinerie' },
      { 503007: 'Cosmétique' },
      { 503008: 'Bijouterie/Horlogerie' },
      { 503009: 'Magasin de jouets/musique' },
      { 503010: 'Librairie' },
      { 503011: 'Fleuriste' }
    ]
  },
  {
    // Alimentation
    id: 7,
    vals: [
      // ###### DAE ######
      { 702001: 'Artisanat alimentaire' }, // # Artisanat_alimentaire
      { 702002: 'Grand prix de la Baguette' },
      { 702003: 'Grand prix de la Pâtisserie' },
      // ###### CCI ######
      { 703001: 'Boulangerie / Pâtisserie / Chocolaterie' },
      { 703002: 'Boucherie / Charcuterie / Fromagerie' },
      { 703003: 'Caviste' },
      { 703004: 'Epicerie fine' },
      { 703005: 'Alimentation générale' }
    ]
  }
];

const OTCP_MAP_OPTIONS_MAPBOX_TOKENS = [
  'pk.eyJ1Ijoicmlrb2pvdWV1ciIsImEiOiJjanNha3pyZ2QwMmdrNDNtdW55YXgxbTJsIn0.Q1UIhfBpnrp2c64nLZCgvg',
  'pk.eyJ1IjoidGFudWtpcGFyZXNzZXV4IiwiYSI6ImNqc2FsM3poeDAxcXk0M21vbHd4MHJka3oifQ.6ea7wTgNO-gMaCscR-vDOA',
  'pk.eyJ1IjoiZ2FsaWRpYWNhcm5pdm9yZSIsImEiOiJjanNhbGEwdTEwMDQ4NDNteXIyeTBpeWd3In0.L6A8XkfBgZT3Ct5QqIMV-Q',
  'pk.eyJ1IjoiY3lwcmlzb3JhbmdlIiwiYSI6ImNqc2FsajBybjAyamQ0NHMyZmt2d2VzZDgifQ.B_2rFqmN5t6Ichfy9EL4Zw',
  'pk.eyJ1IjoiY2hvY2FyZGRpc2NyZXQiLCJhIjoiY2pzYWxxcDByMDM2aTRhbno4NjRzb3B1ZCJ9.Uy1JKVgt6VUYppw_G_sQrQ',
  'pk.eyJ1IjoicXVlbGVhbm9tYWQiLCJhIjoiY2pzYWx0aTUzMDM3NzRhbnp6azY0NmF2NSJ9.IsjlH8LWfaAiOgqqXHWNmQ',
  'pk.eyJ1IjoidGFpZ2FuY2hhc3NldXIiLCJhIjoiY2pzYW1hYThsMDBlMzQzbXk0YWU1ZGNoaiJ9.Cz40mZ9_A6lDpuug_NQosA',
  'pk.eyJ1IjoiZ2VyZW51a2JydW4iLCJhIjoiY2pzYW1nM2g0MDNlYTN5bnoxeWY1ZXZ2ZyJ9.UttYaheHo7dBDvJfgkv-ww',
  'pk.eyJ1IjoiaXJiaXNzb2xpdGFpcmUiLCJhIjoiY2sxcTdvZXB3MTFubzNtdGlreDg0OGhiYyJ9.0ZypbM3-q7DL6mTY8mxaJA',
  'pk.eyJ1IjoicGlyYW5nYWpvdWV1ciIsImEiOiJjazFxZHRicjAxMTdqM25rNmR3M3Z4bm55In0.5S6-CQQK66Vr0GR-QFfMYA',
  'pk.eyJ1IjoicXVva2thYW11c2FudCIsImEiOiJjazFxZHcyNnowN3p2M29vMXI4dnh5czkwIn0.IXhxsJmrIs1MGWzWYhb7eQ',
  'pk.eyJ1IjoibWFydHJlcGVjaGV1c2UiLCJhIjoiY2sxcWR5NmRlMHozbzNob2k1NTl4emdkcSJ9.hgWsBNPfx92w9uN8EfTqHw',
  'pk.eyJ1IjoiY290aW5nYWRpc2NyZXQiLCJhIjoiY2pzYW02M3hqMDNhaTRhbnozcDR5bWVvYyJ9.WGBsAouZ2NoejwBDbZieYw',
  'pk.eyJ1Ijoic2lsZW5lcm9idXN0ZSIsImEiOiJjanNhbWNhZWUwMjVuNDVtbGN4bTJzaWM2In0.we1JmpQuUYxgZ1R0gJRHvw',
  'pk.eyJ1IjoiY2FiZXJ1Y2Fybml2b3JlIiwiYSI6ImNqc2FtaWJibzAzZTI0NGxudnFqbDFkaHUifQ.bXnhZDITTT7mTnMLcQpZeA',
  'pk.eyJ1IjoicGFudGhlcmVuZWJ1bGV1c2UiLCJhIjoiY2pzYWx3ZmRyMDNiZDQzcWNjaHhxN2Q4ZCJ9.lIpOEWLrvNTmVD5d7oUbvg'
];

export const OTCP_MAP_PLACES_TEMPLATE = {
  title: {
    fr: ''
  },
  lang: OTCP_DEFAULT_LOCALE,
  organization_id: ORGANIZATION_ID.OTCP_LOISIR,
  status: 1,
  typology_id: TYPOLOGY_ID.OTCP_PLACES,
  options: {
    css: MAP_OPTIONS_CSS,
    bbox: {
      zoom_init: 13.145369020477514,
      init_bounds: [2.2044167388341407, 48.80520760718491, 2.5024147632515508, 48.90533691919879],
      center_init_lat: 48.85529729730516,
      center_init_lng: 2.3534157510416662
    },
    langs: [OTCP_DEFAULT_LOCALE],
    popup: {
      anchor: 'left',
      offset: [16, 0]
    },
    back_office: {
      internal_name: ''
    },
    mapbox: {
      style: MAP_OPTIONS_MAPBOX_STYLE,
      token: OTCP_MAP_OPTIONS_MAPBOX_TOKENS
    },
    styles: {
      map: MAP_OPTIONS_STYLES_MAP
    },
    map_controls: [
      {
        type: 'NavigationControl',
        options: {
          compass: false
        }
      },
      {
        type: 'GeolocateControl',
        actions: [
          {
            options: {
              max_radius: 10,
              init_radius: 0.3
            },
            function: 'setMapCenter'
          }
        ],
        visibility: 'visible'
      },
      {
        type: 'FullscreenControl'
      }
    ],
    zoom_on_open: 16
  },
  layers: [
    { layer_id: LAYER_ID.LAYER_OTCP_EXPO, idx: 1 },
    { layer_id: LAYER_ID.LAYER_OTCP_LANDMARK, idx: 2 },
    { layer_id: LAYER_ID.LAYER_OTCP_POI_NOT_MEMBER, idx: 3 },
    { layer_id: LAYER_ID.LAYER_OTCP_POI_MEMBER, idx: 4 },
    { layer_id: LAYER_ID.LAYER_OTCP_DAE, idx: 5 },
    { layer_id: LAYER_ID.LAYER_OTCP_UNMISSABLE_PLACES, idx: 6 },
    { layer_id: LAYER_ID.LAYER_METRO_PARIS, idx: 7 }
  ],
  widgets: [273, 275, 299, 302, 305, 306, 313, 317, 318, 319, 320, 321, 322, 323, 335, 336, 338],
  sheets: [
    {
      sheet_id: SHEET_ID.OTCP_PLACES,
      idx: 1,
      options: {
        advanced_filters: [
          {
            filter: [
              {
                key: 'map_id',
                value: '%map_id%',
                operator: '@>'
              }
            ]
          }
        ]
      }
    },
    {
      sheet_id: SHEET_ID.OTCP_UNMISSABLE_PLACES,
      idx: 2,
      options: {}
    },
    {
      sheet_id: SHEET_ID.METRO,
      idx: 3,
      options: {}
    }
  ],
  permissions: [...Object.values(PERMISSION_MAP)]
};

export const OTCP_MAP_WALKING_ROUTE_TEMPLATE = {
  title: {
    fr: ''
  },
  lang: OTCP_DEFAULT_LOCALE,
  organization_id: ORGANIZATION_ID.OTCP_LOISIR,
  status: 1,
  typology_id: TYPOLOGY_ID.OTCP_WALKING_ROUTES,
  options: {
    css: MAP_OPTIONS_CSS,
    bbox: {
      zoom_init: 13.145369020477514,
      init_bounds: [2.2044167388341407, 48.80520760718491, 2.5024147632515508, 48.90533691919879],
      center_init_lat: 48.85529729730516,
      center_init_lng: 2.3534157510416662
    },
    langs: [OTCP_DEFAULT_LOCALE],
    popup: {
      anchor: 'left',
      offset: [16, 0]
    },
    back_office: {
      internal_name: ''
    },
    mapbox: {
      style: MAP_OPTIONS_MAPBOX_STYLE,
      token: OTCP_MAP_OPTIONS_MAPBOX_TOKENS
    },
    styles: {
      map: MAP_OPTIONS_STYLES_MAP
    },
    map_controls: [
      {
        type: 'NavigationControl',
        options: {
          compass: false
        }
      },
      {
        type: 'GeolocateControl',
        actions: [
          {
            options: {
              max_radius: 10,
              init_radius: 0.3
            },
            function: 'setMapCenter'
          }
        ],
        visibility: 'visible'
      },
      {
        type: 'FullscreenControl'
      }
    ],
    zoom_on_open: 16
  },
  layers: [
    { layer_id: LAYER_ID.LAYER_OTCP_WR_POIS, idx: 1 },
    { layer_id: LAYER_ID.LAYER_OTCP_WALKING_ROUTES, idx: 2 },
    { layer_id: LAYER_ID.LAYER_OTCP_POI_NOT_MEMBER, idx: 3 },
    { layer_id: LAYER_ID.LAYER_OTCP_POI_MEMBER, idx: 4 },
    { layer_id: LAYER_ID.LAYER_OTCP_DAE, idx: 5 },
    { layer_id: LAYER_ID.LAYER_OTCP_UNMISSABLE_PLACES, idx: 6 },
    { layer_id: LAYER_ID.LAYER_METRO_PARIS, idx: 7 }
  ],
  widgets: [273, 275, 306, 313, 314, 324, 326, 327, 328, 329, 330, 331, 332, 335, 336, 337, 338],
  sheets: [
    {
      sheet_id: SHEET_ID.OTCP_WR_POIS,
      idx: 1,
      options: {
        advanced_filters: [
          {
            filter: [
              {
                key: 'map_id',
                value: '%map_id%',
                operator: '@>'
              }
            ]
          }
        ]
      }
    },
    {
      sheet_id: SHEET_ID.OTCP_WALKING_ROUTES,
      idx: 2,
      options: {
        filters: {
          map_id: '%map_id%',
          lang: {
            url_search_parameter: 'lang'
          }
        }
      }
    },
    {
      sheet_id: SHEET_ID.OTCP_UNMISSABLE_PLACES,
      idx: 3,
      options: {}
    },
    {
      sheet_id: SHEET_ID.METRO,
      idx: 4,
      options: {}
    }
  ],
  permissions: [...Object.values(PERMISSION_MAP)]
};

export const OTCP_MAP_MULTI_WALKING_ROUTES_TEMPLATE = {
  title: {
    fr: ''
  },
  lang: OTCP_DEFAULT_LOCALE,
  organization_id: ORGANIZATION_ID.OTCP_LOISIR,
  status: 1,
  typology_id: TYPOLOGY_ID.OTCP_MULTI_WALKING_ROUTES,
  options: {
    css: MAP_OPTIONS_CSS,
    bbox: {
      zoom_init: 11.077830354747746,
      init_bounds: [2.2101900270623, 48.81429078641368, 2.468604856502181, 48.90981019820349],
      center_init_lat: 48.86298235615551,
      center_init_lng: 2.335836514658764
    },
    langs: [OTCP_DEFAULT_LOCALE],
    popup: {
      anchor: 'left',
      offset: [16, 0]
    },
    back_office: {
      internal_name: ''
    },
    mapbox: {
      style: MAP_OPTIONS_MAPBOX_STYLE_GREY,
      token: OTCP_MAP_OPTIONS_MAPBOX_TOKENS
    },
    styles: {
      map: MAP_OPTIONS_STYLES_MAP
    },
    map_controls: [
      {
        type: 'NavigationControl',
        options: {
          compass: false
        }
      },
      {
        type: 'GeolocateControl',
        actions: [
          {
            options: {
              max_radius: 10,
              init_radius: 0.3
            },
            function: 'setMapCenter'
          }
        ],
        visibility: 'visible'
      },
      {
        type: 'FullscreenControl'
      }
    ],
    zoom_on_open: 13
  },
  layers: [
    { layer_id: LAYER_ID.LAYER_OTCP_MULTI_WALKING_ROUTE, idx: 1 },
    { layer_id: LAYER_ID.LAYER_OTCP_MULTI_WALKING_ROUTE_TRANSPARENT, idx: 2 },
    { layer_id: LAYER_ID.LAYER_OTCP_UNMISSABLE_PLACES, idx: 3 },
    { layer_id: LAYER_ID.LAYER_METRO_PARIS, idx: 4 }
  ],
  widgets: [273, 275, 302, 313, 338, 383, 385, 386, 387, 388, 389, 391],
  sheets: [
    {
      sheet_id: SHEET_ID.OTCP_WALKING_ROUTES,
      idx: 1,
      options: {
        advanced_filters: [
          {
            filter: [
              {
                key: 'mutli_map_id',
                value: '%map_id%',
                operator: '@>'
              }
            ]
          }
        ],
        filters: {
          lang: {
            url_search_parameter: 'lang'
          }
        }
      }
    },
    {
      sheet_id: SHEET_ID.OTCP_UNMISSABLE_PLACES,
      idx: 2,
      options: {}
    },
    {
      sheet_id: SHEET_ID.METRO,
      idx: 3,
      options: {}
    }
  ],
  permissions: [...Object.values(PERMISSION_MAP)]
};

export const OTCP_MAP_GOOGLE_SHEET_TEMPLATE = {
  title: {
    fr: ''
  },
  lang: OTCP_DEFAULT_LOCALE,
  organization_id: ORGANIZATION_ID.OTCP_LOISIR,
  status: 1,
  typology_id: TYPOLOGY_ID.OTCP_GOOGLE_SHEETS,
  options: {
    css: MAP_OPTIONS_CSS,
    bbox: {
      zoom_init: 13.145369020477514,
      init_bounds: [2.2044167388341407, 48.80520760718491, 2.5024147632515508, 48.90533691919879],
      center_init_lat: 48.85529729730516,
      center_init_lng: 2.3534157510416662
    },
    langs: [OTCP_DEFAULT_LOCALE],
    popup: {
      anchor: 'left',
      offset: [16, 0]
    },
    back_office: {
      internal_name: ''
    },
    mapbox: {
      style: MAP_OPTIONS_MAPBOX_STYLE,
      token: OTCP_MAP_OPTIONS_MAPBOX_TOKENS
    },
    styles: {
      map: MAP_OPTIONS_STYLES_MAP
    },
    map_controls: [
      {
        type: 'NavigationControl',
        options: {
          compass: false
        }
      },
      {
        type: 'GeolocateControl',
        actions: [
          {
            options: {
              max_radius: 10,
              init_radius: 0.3
            },
            function: 'setMapCenter'
          }
        ],
        visibility: 'visible'
      },
      {
        type: 'FullscreenControl'
      }
    ],
    zoom_on_open: 16,
    actions_mobile: {
      _rendered_pois_user_layer_207: [
        {
          options: {
            poi: [['data._.slug', '=', '{{url.slug}}']],
            highlight: true,
            init_zoom: 16
          },
          function: 'center-on-poi'
        }
      ]
    },
    url_parameters: {
      slug: {
        type: 'string'
      }
    },
    actions_desktop: {
      _rendered_pois_user_layer_207: [
        {
          options: {
            poi: [['data._.slug', '=', '{{url.slug}}']],
            'center-on': true,
            highlight: true,
            init_zoom: 16
          },
          function: 'open-poi'
        }
      ]
    }
  },
  layers: [
    { layer_id: LAYER_ID.LAYER_MF_COLORED_CIRCLE, idx: 1, layer_options: { sheet: '%SHEET_ID%' } },
    { layer_id: LAYER_ID.LAYER_MF_SPRITE_MARKER, idx: 2, layer_options: { sheet: '%SHEET_ID%' } },
    { layer_id: LAYER_ID.LAYER_MF_TEXT, idx: 3, layer_options: { sheet: '%SHEET_ID%' } },
    { layer_id: LAYER_ID.LAYER_OTCP_LANDMARK, idx: 4 },
    { layer_id: LAYER_ID.LAYER_OTCP_UNMISSABLE_PLACES, idx: 5 },
    { layer_id: LAYER_ID.LAYER_METRO_PARIS, idx: 6 }
  ],
  /*
  widgets: [273, 275, 299, 302, 305, 306, 313, 317, 318, 319, 320, 321, 322, 323, 335, 336, 338],
  */
  widgets_with_options: [
    { widget_id: 440 },
    { widget_id: 441 },
    { widget_id: 442 },
    { widget_id: 443 },
    { widget_id: 444 },
    { widget_id: 445 },
    { widget_id: 446 },
    { widget_id: 447 },
    { widget_id: 448 },
    { widget_id: 449 },
    { widget_id: 450, widget_options: { target: { mode: 'all', sheets: ['%SHEET_ID%'], order_column: 'data.row_number', forward_filters: true } } },
    { widget_id: 451 },
    { widget_id: 452 },
    { widget_id: 453 },
    { widget_id: 454 },
    { widget_id: 455 },
    { widget_id: 457 },
    {
      widget_id: 458,
      widget_options: {
        targets: [
          {
            type: 'options',
            label: {
              de: '<span class="viz-label">{{value}}</span>',
              en: '<span class="viz-label">{{value}}</span>',
              es: '<span class="viz-label">{{value}}</span>',
              fr: '<span class="viz-label">{{value}}</span>'
            },
            sheet: '%SHEET_ID%',
            column: 'filter',
            target: 'all',
            trContext: ''
          }
        ]
      }
    }
  ],
  sheets: [
    {
      sheet_id: '%SHEET_ID%',
      idx: 1
    },
    {
      sheet_id: SHEET_ID.OTCP_UNMISSABLE_PLACES,
      idx: 2,
      options: {}
    },
    {
      sheet_id: SHEET_ID.METRO,
      idx: 3,
      options: {}
    }
  ],
  permissions: [...Object.values(PERMISSION_MAP)]
};

export const HTML_TEMPLATE_DESKTOP_MAP_WIDTH = '700px';
export const HTML_TEMPLATE_DESKTOP_MAP_HEIGHT = '500px';
export const HTML_TEMPLATE_MOBILE_MAP_WIDTH = '340px';
export const HTML_TEMPLATE_MOBILE_MAP_HEIGHT = '450px';
