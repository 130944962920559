export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const globalConfig = {
  deployedInProd: process.env.REACT_APP_DEPLOYED_IN_PROD.trim() === 'true' || process.env.REACT_APP_DEPLOYED_IN_PROD === true,
  apiRoot: process.env.REACT_APP_API_URL,
  frontUrl: process.env.REACT_APP_FRONT_URL,
  redisStore: process.env.REACT_APP_REDIS_STORE.trim() === 'true' || process.env.REACT_APP_REDIS_STORE === true,
  defaultLogin: process.env.REACT_APP_DEFAULT_LOGIN,
  defaultPassword: process.env.REACT_APP_DEFAULT_PASSWORD,
  displayDeviasMenu: process.env.REACT_APP_DISPLAY_DEVIAS_MENU.trim() === 'true' || process.env.REACT_APP_DISPLAY_DEVIAS_MENU === true,
  devDefaultEmail: process.env.REACT_APP_DEV_DEFAULT_EMAIL,
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  googleApiClientId: process.env.REACT_APP_GOOGLE_API_CLIENT_ID,
  googleApiRedirectUri: process.env.REACT_APP_GOOGLE_API_REDIRECT_URI,
  s3Bucket: process.env.REACT_APP_IMG_S3_BUCKET
};
