import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axiosVizity';
import { useHistory } from 'react-router-dom';
import { PERMISSION_ID } from 'src/vizity/constantsVizity';
import getErrorMessage from 'src/utils/errorUtils';
import { isOrganizationConfigurationOk } from 'src/utils/mapFactoryUtils';
import { isOrganizationOtcpMember } from 'src/utils/partnersUtils';
import useSettings from 'src/hooks/useSettings';
import { MAP_FACTORY_SUBSCRIPTION, DAYS_OF_TRIAL } from 'src/vizity/constantsVizity';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = accessToken => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers['X-API-KEY'] = accessToken;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers['X-API-KEY'];
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'UPDATE_ORGANIZATION': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  updateOrganization: () => Promise.resolve()
});

const getOrganizationId = (admin, email, members, settingsOrganizationId) => {
  let isAdmin = admin === 1 && email && email.endsWith('@vizity.com');
  let currentOrganizationId = -1;
  if (settingsOrganizationId) {
    if (isAdmin) {
      currentOrganizationId = settingsOrganizationId;
    } else {
      let userSpacePermission = false;
      let organizationAllowed = false;
      if (members && Symbol.iterator in Object(members)) {
        for (const member of members) {
          if ('permissions' in member) {
            if (member.permissions.includes(PERMISSION_ID.OTCP_USER_S_SPACE_MENU)) {
              userSpacePermission = true;
              break;
            }
            if (member['organization_id'] === settingsOrganizationId) {
              organizationAllowed = true;
            }

            if (userSpacePermission && organizationAllowed) {
              currentOrganizationId = settingsOrganizationId;
              break;
            }
          }
        }
      } else {
        console.warn('User ' + email + 'is not member of any organization !');
      }
    }
  }
  if (currentOrganizationId < 0 && members && members.length > 0) {
    currentOrganizationId = members[0]['organization_id'];
  }
  return { isAdmin, currentOrganizationId };
};

const internalUpdateOrganization = async (user, newId, enqueueSnackbar, intl) => {
  try {
    user.currentOrganizationId = newId;
    user.isMapFactoryOrganization = false;
    user.isOtcpMember = false;
    const response = await axios.get('/api/organization/' + newId);
    if (response.status === 200 && response.data) {
      if ('options' in response.data) {
        user.currentOrganizationOptions = response.data.options;
        user.isMapFactoryOrganization = isOrganizationConfigurationOk(response.data.options);
        if (user.isMapFactoryOrganization) {
          if ('map_factory' in response.data.options)
            if ('have_seen_disclaimer_message' in response.data.options.map_factory)
              user.have_seen_disclaimer_message = response.data.options.map_factory.have_seen_disclaimer_message
          if ('during_test_period' in response.data.options.map_factory)
            user.during_test_period = response.data.options.map_factory.during_test_period
          if ('subscription' in response.data.options.map_factory && 'subscription_date' in response.data.options.map_factory && response.data.options.map_factory.subscription_date) {
            const start = new Date(response.data.options.map_factory.subscription_date)
            if (!isNaN(start)) {
              const year = start.getFullYear()
              const month = start.getMonth()
              const day = start.getDate()
              const start_date = new Date(year, month, day + MAP_FACTORY_SUBSCRIPTION[response.data.options.map_factory.subscription]['nb_days'])
              if (start_date < new Date()) {

                user.has_a_valid_subscription = false
              }
              if (user.has_a_valid_subscription)
                user.day_left = Math.ceil(Math.abs(start_date - new Date()) / 86400000)
            }
          }
          else if ('register_date' in response.data.options.map_factory) {
            user.has_a_valid_subscription = false
            const start = new Date(response.data.options.map_factory.register_date)
            if (!isNaN(start)) {
              const year = start.getFullYear()
              const month = start.getMonth()
              const day = start.getDate()
              const start_date = new Date(year, month, day + DAYS_OF_TRIAL)
              if (start_date >= new Date()) {
                user.during_test_period = true
              }
              if (user.during_test_period)
                user.day_left = Math.ceil(Math.abs(start_date - new Date()) / 86400000)
            }
          }
        }
        else {
          user.isOtcpMember = isOrganizationOtcpMember(response.data.options);
        }
      }
      if (!user.has_a_valid_subscription && !user.during_test_period)
        user.members = []
      user.currentOrganizationName =
        'fr' in response.data.name ? response.data.name.fr : 'en' in response.data.name ? response.data.name.en : 'No organization name !';
    } else {
      throw new Error('Bad response content');
    }
  } catch (err) {
    console.error(err);

    enqueueSnackbar(
      intl.formatMessage(
        {
          id: 'ws.error.generic',
          defaultMessage: 'Web Service error : {ws}, {error}'
        },
        { ws: 'Update organization', error: getErrorMessage(err) }
      ),
      {
        variant: 'error'
      }
    );
  }
};

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { settings } = useSettings();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const internalLogin = async response => {
    const {
      id,
      firstname,
      lastname,
      //singlename,
      email,
      picture_id,
      //status,
      admin,
      token,
      //expire
      members,
    } = response.data;

    const { isAdmin, currentOrganizationId } = getOrganizationId(admin, email, members, settings.vizitySettingsOrganizationId);

    const user = {
      id: id,
      avatar: picture_id,
      email: email,
      name: firstname + ' ' + lastname,
      //tier: identifier,
      tier: email,
      currentOrganizationId: currentOrganizationId,
      admin: isAdmin,
      isMapFactoryOrganization: false,
      isOtcpMember: false,
      members: members,
      during_test_period: false,
      has_a_valid_subscription: true,
      have_seen_disclaimer_message: true,
      day_left: 0,
    };

    setSession(token);
    await internalUpdateOrganization(user, currentOrganizationId, enqueueSnackbar, intl);

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const signInWithGoogle = async credential => {
    try {
      const response = await axios.post('/api/auth/account/google-sign-in', credential);

      internalLogin(response);
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        intl.formatMessage(
          {
            id: 'ws.error.generic',
            defaultMessage: 'Web Service error : {ws}, {error}'
          },
          { ws: 'User Login', error: getErrorMessage(err) }
        ),
        {
          variant: 'error'
        }
      );
      throw Error(err);
    }
  };

  const login = async (from_email, form_password) => {
    try {
      const response = await axios.post('/api/auth/account/login', {
        account: from_email,
        password: form_password
      });
      internalLogin(response);
    } catch (err) {
      if (err === 'authentication failed' || err === 'check your email to activate your account')
        throw Error(err);
      enqueueSnackbar(
        intl.formatMessage(
          {
            id: 'ws.error.generic',
            defaultMessage: 'Web Service error : {ws}, {error}'
          },
          { ws: 'User Login', error: getErrorMessage(err) }
        ),
        {
          variant: 'error'
        }
      );
    }
  };

  const updateOrganization = async newId => {
    const user = state.user;
    user.currentOrganizationId = newId;
    user.isMapFactoryOrganization = false;

    await internalUpdateOrganization(user, newId, enqueueSnackbar, intl);

    history.push('/app');

    dispatch({
      type: 'UPDATE_ORGANIZATION',
      payload: {
        isAuthenticated: true,
        user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/auth/account/whoami');

          const {
            id,
            firstname,
            lastname,
            //singlename,
            email,
            picture_id,
            //status,
            admin,
            //expire
            members
          } = response.data;

          const { isAdmin, currentOrganizationId } = getOrganizationId(admin, email, members, settings.vizitySettingsOrganizationId);

          const user = {
            id: id,
            avatar: picture_id,
            email: email,
            name: firstname + ' ' + lastname,
            tier: email,
            admin: isAdmin,
            currentOrganizationId: currentOrganizationId,
            during_test_period: false,
            has_a_valid_subscription: true,
            have_seen_disclaimer_message: true,
            members: members,
            day_left: 0
          };
          await internalUpdateOrganization(user, currentOrganizationId, enqueueSnackbar, intl);

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });

        enqueueSnackbar(
          intl.formatMessage(
            {
              id: 'ws.error.generic',
              defaultMessage: 'Web Service error : {ws}, {error}'
            },
            { ws: 'GET user account infos', error: err.message ? err.message : String(err) }
          ),
          {
            variant: 'error'
          }
        );
      }
    };

    initialize();
  }, [enqueueSnackbar, intl, settings.vizitySettingsOrganizationId]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        signInWithGoogle,
        logout,
        register,
        updateOrganization
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
