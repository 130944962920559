import React, { useState, useRef, useEffect, useCallback } from 'react';
import { capitalCase } from 'change-case';
import { Badge, Box, Button, FormControlLabel, IconButton, Popover, SvgIcon, Switch, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import { PERMISSION_ID, MAP_OTCP_ORGANIZATION } from 'src/vizity/constantsVizity';
import { useIntl, FormattedMessage } from 'react-intl';
import useAuth from 'src/hooks/useAuth';
import { hasPermission } from 'src/utils/permissionUtils';
import axios from 'src/utils/axiosVizity';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import getErrorMessage from 'src/utils/errorUtils';

const useStyles = makeStyles(theme => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { settings, saveSettings } = useSettings();
  const { user, updateOrganization } = useAuth();
  const [organizations, setOrganizations] = useState(null);
  const [canAccessUserSpaceMenu] = useState(hasPermission(user, PERMISSION_ID.OTCP_USER_S_SPACE_MENU, true));
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    vizitySettingsOrganizationId: user.currentOrganizationId,
    displayDeviasMenu: settings.displayDeviasMenu
  });
  const intl = useIntl();

  const [userSpaces, setUserSpace] = useState(
    Object.fromEntries(user.admin === true ? new Map() : canAccessUserSpaceMenu === true ? MAP_OTCP_ORGANIZATION : new Map())
  );

  const getListOrganization = useCallback(async () => {
    try {
      const page = 1;
      const limit = 100;
      const response = await axios.get('/api/organization', {
        params: {
          page: page,
          per_page: limit
        }
      });
      if (isMountedRef.current) {
        const allOrganization = response.data;
        setOrganizations(allOrganization.filter(o => o.id > 37 && 'options' in o && 'map_factory' in o.options));
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar(
        intl.formatMessage(
          {
            id: 'ws.error.generic',
            defaultMessage: 'Web Service error : {ws}, {error}'
          },
          { ws: 'GET all organizations', error: getErrorMessage(err) }
        ),
        {
          variant: 'error'
        }
      );
    }
  }, [isMountedRef, enqueueSnackbar, intl]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
    updateOrganization(values.vizitySettingsOrganizationId);
    setOpen(false);
  };

  useEffect(() => {
    if (organizations) {
      let os = new Map(MAP_OTCP_ORGANIZATION);

      organizations.forEach(orga => {
        os.set(Number.parseInt(orga.id), orga.name['fr']);
      });

      setUserSpace(Object.fromEntries(os));
    }
  }, [organizations]);

  useEffect(() => {
    if (user.admin === true) {
      getListOrganization();
    }
  }, [getListOrganization, user.admin]);

  /*
  useEffect(() => {
    if (user.currentOrganizationId !== values.vizitySettingsOrganizationId) {
      setValues({
        ...values,
        vizitySettingsOrganizationId: user.currentOrganizationId
      });
    }
  }, [values, user.currentOrganizationId]);
*/

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: 'settings.page.title',
          defaultMessage: 'Settings'
        })}
      >
        <Badge overlap="rectangular" color="secondary" variant="dot" classes={{ badge: classes.badge }}>
          <IconButton color="inherit" onClick={handleOpen} ref={ref}>
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          <FormattedMessage id="settings.popover.title" defaultMessage="Settings" />
        </Typography>
        {/* 
        <Box mt={2} px={1}>
          <FormControlLabel
            control={
              <Switch
                checked={values.direction === 'rtl'}
                edge="start"
                name="direction"
                onChange={event => handleChange('direction', event.target.checked ? 'rtl' : 'ltr')}
              />
            }
            label="intl.formatMessage({
              id: 'settings.responsive.typography.rtl',
              defaultMessage: 'RTL'
            })}"
          />
        </Box>
        */}
        <Box mt={2} px={1}>
          <FormControlLabel
            control={
              <Switch
                checked={values.responsiveFontSizes}
                edge="start"
                name="direction"
                onChange={event => handleChange('responsiveFontSizes', event.target.checked)}
              />
            }
            label={intl.formatMessage({
              id: 'settings.responsive.font.sizes',
              defaultMessage: 'Responsive font sizes'
            })}
          />
        </Box>
        {user.admin && (
          <Box mt={2} px={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.displayDeviasMenu}
                  edge="start"
                  name="direction"
                  onChange={event => handleChange('displayDeviasMenu', event.target.checked)}
                />
              }
              label={intl.formatMessage({
                id: 'settings.display.devias.menu',
                defaultMessage: 'Display Devias menu'
              })}
            />
          </Box>
        )}
        <Box mt={2}>
          <TextField
            fullWidth
            label={intl.formatMessage({
              id: 'settings.theme',
              defaultMessage: 'Theme'
            })}
            name="theme"
            onChange={event => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map(theme => (
              <option key={theme} value={theme}>
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        {(user.admin || canAccessUserSpaceMenu) && (
          <Box mt={2}>
            <TextField
              fullWidth
              label={intl.formatMessage({
                id: 'settings.user.space',
                defaultMessage: 'User Space'
              })}
              name="vizitySettingsOrganizationId"
              onChange={event => handleChange('vizitySettingsOrganizationId', Number.parseInt(event.target.value))}
              select
              SelectProps={{ native: true }}
              value={values.vizitySettingsOrganizationId}
              variant="outlined"
            >
              {Object.entries(userSpaces).map(entry => (
                <option key={entry[0]} value={entry[0]}>
                  {entry[1]}
                </option>
              ))}
            </TextField>
          </Box>
        )}
        <Box mt={2}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleSave}>
            <FormattedMessage id="settings.save" defaultMessage="Save Settings" />
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;
