import { OTCP_SUPPORTED_LOCALES } from 'src/vizity/partners/OtcpConstants';
import { MAP_FACTORY_SUPPORTED_LOCALES } from 'src/vizity/partners/MapFactoryConstants';
import _ from 'lodash';

export const getOtcpLocale = intl => {
  return OTCP_SUPPORTED_LOCALES.includes(intl.locale) ? intl.locale : 'fr';
};

export const getMapFactoryLocale = intl => {
  return MAP_FACTORY_SUPPORTED_LOCALES.includes(intl.locale) ? intl.locale : 'fr';
};

export const fillOtcpMapTemplate = (currentOrganizationId, organizationOptions, template) => {
  template.organization_id = currentOrganizationId;
  template.options.bbox = _.get(organizationOptions, 'back_office.default_settings.bbox');

  if (_.has(organizationOptions, 'back_office.default_settings.zoom_on_open')) {
    template.options.zoom_on_open = _.get(organizationOptions, 'back_office.default_settings.zoom_on_open');
  }

  return template;
};

export const isOrganizationOtcpMember = organizationOptions => {
  return organizationOptions && _.has(organizationOptions, 'map_factory.is_otcp_member');
};

export const MF_PARTNERS_SHEET_TYPE = {
  OTCP_MEMBER_PREMISES: { path: 'premises', template: '{{SHEET_ID.PREMISES}}' }
};

export const getOtcpSheetId = (organizationOptions, MF_SHEET_TYPE) => {
  return _.get(organizationOptions, 'otcp_members.sheets.' + MF_SHEET_TYPE.path);
};
