import _ from 'lodash';

export const MF_MAP_TYPE = {
  ALL_WALKING_ROUTES: { path: 'all_walking_routes', template: '{{MAP_ID.ALL_WALKING_ROUTES}}' }
};

export const MF_SHEET_TYPE = {
  PLACES: { path: 'places', template: '{{SHEET_ID.PLACES}}' },
  WALKING_ROUTES: { path: 'walking_routes', template: '{{SHEET_ID.WALKING_ROUTES}}' },
  WALKING_ROUTES_POI: { path: 'walking_routes_poi', template: '{{SHEET_ID.WALKING_ROUTES_POI}}' }
};

export const MF_LAYER_TYPE = {
  GENERIC_POI: { path: 'generic_poi', template: '{{LAYER_ID.GENERIC_POI}}' },
  WALKING_ROUTES: { path: 'walking_routes', template: '{{LAYER_ID.WALKING_ROUTES}}' },
  WALKING_LANDMARK: { path: 'walking_landmark', template: '{{LAYER_ID.WALKING_LANDMARK}}' },
  WALKING_ROUTES_POI: { path: 'walking_routes_poi', template: '{{LAYER_ID.WALKING_ROUTES_POI}}' },
  MULTI_WALKING_ROUTES_POI: { path: 'multi_walking_routes_poi', template: '{{LAYER_ID.MULTI_WALKING_ROUTES_POI}}' }
};

export const isOrganizationConfigurationOk = organizationOptions => {
  return (
    organizationOptions &&
    _.has(organizationOptions, 'map_factory.maps') &&
    _.has(organizationOptions, 'map_factory.layers') &&
    _.has(organizationOptions, 'map_factory.sheets') &&
    _.has(organizationOptions, 'map_factory.google_drive_directory_id') &&
    !_.has(organizationOptions, 'map_factory.is_otcp_organization') &&
    !_.has(organizationOptions, 'map_factory.is_otcp_member')
  );
};

export const isOrganizationFullConfigurationOk = organizationOptions => {
  let result = true && organizationOptions && 'map_factory' in organizationOptions;

  if (result) {
    for (const layerType of Object.values(MF_LAYER_TYPE)) {
      result &= _.has(organizationOptions, 'map_factory.layers.' + layerType.path);
    }
  }

  if (result) {
    for (const sheetType of Object.values(MF_SHEET_TYPE)) {
      result &= _.has(organizationOptions, 'map_factory.sheets.' + sheetType.path);
    }
  }

  if (result) {
    for (const mapType of Object.values(MF_MAP_TYPE)) {
      result &= _.has(organizationOptions, 'map_factory.maps.' + mapType.path);
    }
  }

  return !!result;
};

export const getDefaultMapCenter = organizationOptions => {
  const latitude = _.get(organizationOptions, 'back_office.default_settings.bbox.center_init_lat');
  const longitude = _.get(organizationOptions, 'back_office.default_settings.bbox.center_init_lng');

  return {
    latitude,
    longitude
  };
};

export const getSheetId = (organizationOptions, MF_SHEET_TYPE) => {
  return _.get(organizationOptions, 'map_factory.sheets.' + MF_SHEET_TYPE.path);
};

export const getMapId = (organizationOptions, MF_MAP_TYPE) => {
  return _.get(organizationOptions, 'map_factory.maps.' + MF_MAP_TYPE.path);
};

export const getFrontUrl = (organizationOptions, deployedInProd, frontUrl) => {
  if (deployedInProd === true) {
    return _.get(organizationOptions, 'front_url', frontUrl);
  }

  return frontUrl;
};

export const fillMapTemplate = (organizationOptions, currentOrganizationId, template) => {
  for (const templateSheet of template.sheets) {
    for (const sheetType of Object.values(MF_SHEET_TYPE)) {
      if (templateSheet.sheet_id === sheetType.template) {
        templateSheet.sheet_id = _.get(organizationOptions, 'map_factory.sheets.' + sheetType.path);
        continue;
      }
    }
  }

  for (const templateLayer of template.layers) {
    for (const layerType of Object.values(MF_LAYER_TYPE)) {
      if (templateLayer.layer_id === layerType.template) {
        templateLayer.layer_id = _.get(organizationOptions, 'map_factory.layers.' + layerType.path);
        continue;
      }
    }
  }

  template.organization_id = currentOrganizationId;
  template.options.bbox = _.get(organizationOptions, 'back_office.default_settings.bbox');

  if (_.has(organizationOptions, 'back_office.default_settings.zoom_on_open')) {
    template.options.zoom_on_open = _.get(organizationOptions, 'back_office.default_settings.zoom_on_open');
  }

  return template;
};

export const fillMapTemplateSheetId = (template, sheetId) => {
  for (const templateSheet of template.sheets) {
    if (templateSheet.sheet_id === '%SHEET_ID%') {
      templateSheet.sheet_id = sheetId;
    }
  }

  for (const templateLayer of template.layers) {
    if ('layer_options' in templateLayer && 'sheet' in templateLayer.layer_options && templateLayer.layer_options.sheet === '%SHEET_ID%') {
      templateLayer.layer_options.sheet = sheetId;
    }
  }

  if ('widgets_with_options' in template) {
    for (const widget of template.widgets_with_options) {
      if ('widget_options' in widget) {
        if ('target' in widget.widget_options && 'sheets' in widget.widget_options.target && widget.widget_options.target.sheets.length > 0) {
          widget.widget_options.target.sheets = Array.from(widget.widget_options.target.sheets.map(val => (val === '%SHEET_ID%' ? sheetId : val)));
        } else if ('targets' in widget.widget_options) {
          for (let target of widget.widget_options.targets) {
            if ('sheet' in target && target.sheet === '%SHEET_ID%') {
              target.sheet = sheetId;
            }
          }
        }
      }
    }
  }

  return template;
};
