import { PERMISSION_ID } from './../vizity/constantsVizity';

export const hasPermission = (user, permissionId, anyOrganization = false) => {
  if (user) {
    if (user.admin === true || permissionId === PERMISSION_ID.NO_RESTRICTION) {
      return true;
    }

    if (user.members && permissionId) {
      for (const member of user.members) {
        if ((anyOrganization || user.currentOrganizationId === member.organization_id) && 'permissions' in member) {
          if (member.permissions.includes(permissionId)) {
            return true;
          }
        }
      }
    }
  }

  return false;
};
