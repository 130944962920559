import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'src/__mocks__';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import { IntlProvider } from 'react-intl';
import App from 'src/App';

enableES5();

function loadLocaleData(locale) {
  switch (locale) {
    case 'fr':
      return import('./lang/fr-compiled.json');
    default:
      return import('./lang/en-compiled.json');
  }
}

function loadMaterialUiLocaleData(locale) {
  switch (locale) {
    case 'fr':
      return import('@material-ui/core/locale').then(locale => locale.frFR);
    default:
      return import('@material-ui/core/locale').then(locale => locale.enUS);
  }
}

function loadMaterialUiPickersLocaleData(locale) {
  switch (locale) {
    case 'fr':
      return import('moment/locale/fr');
    default:
      return null;
  }
}

const language = navigator.language.split(/[-_]/)[0];

async function bootstrapApplication(locale) {
  const messages = await loadLocaleData(locale);
  const materialUiMessage = await loadMaterialUiLocaleData(locale);
  const materialUiPickerMessage = await loadMaterialUiPickersLocaleData(locale);
  ReactDOM.render(
    <IntlProvider locale={language} messages={messages}>
      <Provider store={store}>
        <SettingsProvider>
          <App messages={materialUiMessage} pickerMessage={materialUiPickerMessage} locale={locale} />
        </SettingsProvider>
      </Provider>
    </IntlProvider>,
    document.getElementById('root')
  );
}

bootstrapApplication(language);

serviceWorker.register();
