import axios from 'axios';
import { globalConfig } from 'src/config';

const axiosInstance = axios.create({
  baseURL: globalConfig.apiRoot ? globalConfig.apiRoot : 'https://api-preprod.vizity.io/'
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    console.error('HTTP Error : ' + error.message);
    //return Promise.reject((error.response && error.response.data) || 'Something went wrong');
    return Promise.reject((error.response && error.response.data) || error.message);
  }
);

export default axiosInstance;
