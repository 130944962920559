import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import { hasPermission } from 'src/utils/permissionUtils';
import { globalConfig } from 'src/config';

const RouteAuthGuard = ({ children, permissionId, path }) => {
  const { isAuthenticated, user } = useAuth();

  if (path.startsWith('/vizity')) {
    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }

    if (permissionId) {
      if (!(user.admin === true || hasPermission(user, permissionId))) {
        if (process.env.NODE_ENV !== 'production' && globalConfig.deployedInProd === false) {
          console.warn(
            'Permission not found, userId=' + user.id + ', organizationId=' + user.currentOrganizationId + ', permissionId=' + permissionId + ',  path=' + path
          );
        }

        return <Redirect to="/404" />;
      }
    } else {
      if (process.env.NODE_ENV !== 'production' && globalConfig.deployedInProd === false) {
        console.warn('No permission set,  path=' + path);
      }
      return <Redirect to="/404" />;
    }
  }

  return <>{children}</>;
};

RouteAuthGuard.propTypes = {
  children: PropTypes.node,
  permissionId: PropTypes.number,
  path: PropTypes.string
};

export default RouteAuthGuard;
