import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import RouteAuthGuard from 'src/components/vizity/RouteAuthGuard';
import EmptyAuthGuard from 'src/components/vizity/EmptyAuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { ORGANIZATION_ID, PERMISSION_ID, OTCP_ORGANIZATION_IDS } from './vizity/constantsVizity';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || EmptyAuthGuard;
        const RouteGuard = route.component ? RouteAuthGuard : EmptyAuthGuard;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const organizationsId = route.guard ? route.organizationsId : undefined;
        const organizationId = route.guard ? route.organizationId : undefined;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard
                organizationId={organizationId}
                organizationsId={organizationsId}
                mapFactoryOrganization={!!route.mapFactoryOrganization}
                otcpMember={!!route.otcpMember}
                path={route.path}
              >
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <RouteGuard permissionId={route.permissionId} path={route.path}>
                      <Component {...props} />
                    </RouteGuard>
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: ['/app/mail/label/:customLabel/:mailId?', '/app/mail/:systemLabel/:mailId?'],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/invoice/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/order/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/project/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/project/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/project/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/project/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app',
        //component: () => <Redirect to="/app/reports/dashboard" />
        component: lazy(() => import('src/views/vizity/default'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/APICallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '/vizity/admin',
    guard: AuthGuard,
    layout: DashboardLayout,
    organizationId: ORGANIZATION_ID.VIZITY,
    routes: [
      {
        exact: true,
        path: '/vizity/admin/create-organization',
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        component: lazy(() => import('src/views/vizity/admin/create-organization'))
      },
      {
        exact: true,
        path: '/vizity/admin/create-user',
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        component: lazy(() => import('src/views/vizity/admin/create-user'))
      },
      {
        exact: true,
        path: '/vizity/admin/add-image',
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        component: lazy(() => import('src/views/vizity/admin/add-image'))
      },
      {
        path: '/vizity/admin/permissions-management/global-permissions',
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        component: lazy(() => import('src/views/vizity/admin/permissions-management/global-permissions'))
      },
      {
        exact: true,
        path: '/vizity/admin/permissions-management/organization-permissions',
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        component: lazy(() => import('src/views/vizity/admin/permissions-management/organization-permissions'))
      },
      {
        exact: true,
        path: '/vizity/admin/permissions-management/map-permissions',
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        component: lazy(() => import('src/views/vizity/admin/permissions-management/map-permissions'))
      },
      {
        exact: true,
        path: '/vizity/admin/permissions-management/sheet-permissions',
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        component: lazy(() => import('src/views/vizity/admin/permissions-management/sheet-permissions'))
      },
      {
        exact: true,
        path: '/vizity/admin/apidae',
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        component: lazy(() => import('src/views/vizity/admin/apidae'))
      },
      {
        exact: true,
        path: '/vizity/admin/subscription',
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        component: lazy(() => import('src/views/vizity/admin/subscription'))
      },
      {
        exact: true,
        path: '/vizity/admin/test',
        permissionId: PERMISSION_ID.VIZITY_ADMIN,
        component: lazy(() => import('src/views/vizity/admin/test'))
      }
    ]
  },

  {
    /* ------ Map factory -------*/
    path: '/vizity/mf',
    guard: AuthGuard,
    layout: DashboardLayout,
    mapFactoryOrganization: true,
    routes: [
      /* ----------------------------------- MAPS - DASHBOARD --------------------------------------*/
      {
        exact: true,
        path: '/vizity/mf/dashboard',
        component: lazy(() => import('src/views/vizity/mapFactory/dashboard/dashboardView.js')),
        permissionId: PERMISSION_ID.NO_RESTRICTION
      },
      /* ----------------------------------- MAPS - SUBSCRIPTION ----------------------------------*/
      {
        exact: true,
        path: '/vizity/mf/formula',
        component: lazy(() => import('src/views/vizity/mapFactory/pricing/PricingView.js')),
        permissionId: PERMISSION_ID.NO_RESTRICTION
      },
      {
        exact: true,
        path: '/vizity/mf/conf/subscription',
        component: lazy(() => import('src/views/vizity/mapFactory/subscription/index.js')),
        permissionId: PERMISSION_ID.NO_RESTRICTION
      },

      /* ----------------------------------- MAPS - GOOGLE SHEETS ----------------------------------*/
      {
        exact: true,
        path: '/vizity/mf/maps/gs',
        component: lazy(() => import('src/views/vizity/mapFactory/maps/googleSheets/MapListView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_GOOGLE_SHEETS_MAPS
      },
      /* ----------------------------------- MAPS - PLACES ----------------------------------*/
      {
        exact: true,
        path: '/vizity/mf/maps/places',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapListView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/map/:mapId/edit/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/poi/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/PoiEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/poi/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/PoiEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/landmark/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/LandmarkEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/landmark/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/LandmarkEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_PLACES_MAPS
      },

      /* ----------------------------------- MAPS - WALKING ROUTES ----------------------------------*/
      {
        exact: true,
        path: '/vizity/mf/maps/walkingRoutes',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapListView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/maps/mapWalkingRoutePoi/map/:mapId/sheet/:sheetId/poi/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesStepEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/maps/mapWalkingRoutePoi/map/:mapId/sheet/:sheetId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesStepEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/maps/mapWalkingRoutePoi/map/:mapId/sheet/:sheetId/cp/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesCpEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/maps/mapWalkingRoutePoi/map/:mapId/sheet/:sheetId/edit/cp/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesCpEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/maps/mapWalkingRoute/map/:mapId/sheet/:sheetId/editInfo/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesInfosEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/maps/mapWalkingRoute/map/:mapId/sheet/:sheetId/editPath/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesPathEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_WALKING_ROUTES_MAPS
      },

      /* ----------------------------------- MAPS - MULTI-WALKING ROUTES ----------------------------------*/
      {
        exact: true,
        path: '/vizity/mf/maps/multiWalkingRoutes',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapListView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_MULTI_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/mf/map/:mapId/edit/multiWalkingRoutes/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/multi-waling-routes/MapMultiWalkingRoutesEditView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_MULTI_WALKING_ROUTES_MAPS
      },

      /* ----------------------------------- MAPS - COMMONS ----------------------------------*/
      {
        exact: true,
        path: '/vizity/mf/map/:mapId/settings/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapSettingsView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_DEFAULT_SETTINGS
      },
      {
        exact: true,
        path: '/vizity/mf/map/settings/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapSettingsView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_DEFAULT_SETTINGS
      },
      {
        exact: true,
        path: '/vizity/mf/map/settings/duplicate/:duplicateMapId/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapSettingsView')),
        permissionId: PERMISSION_ID.MAP_FACTORY_DEFAULT_SETTINGS
      },

      /* ----------------------------- CONFIGURATION - DEFAULT SETTINGS ----------------------------------*/

      {
        exact: true,
        path: '/vizity/mf/conf/default-settings',
        permissionId: PERMISSION_ID.MAP_FACTORY_DEFAULT_SETTINGS,
        component: lazy(() => import('src/views/vizity/otcp/default-settings'))
      }
    ]
  },
  {
    path: '/vizity/otcp',
    guard: AuthGuard,
    layout: DashboardLayout,
    organizationsId: OTCP_ORGANIZATION_IDS,
    routes: [
      /* ----------------------------------- MAPS - GOOGLE SHEETS ----------------------------------*/
      {
        exact: true,
        path: '/vizity/otcp/maps/gs',
        component: lazy(() => import('src/views/vizity/mapFactory/maps/googleSheets/MapListView')),
        permissionId: PERMISSION_ID.OTCP_FACTORY_GOOGLE_SHEETS_MAPS
      },
      /* ----------------------------------- MAPS - PLACES ----------------------------------*/
      {
        exact: true,
        path: '/vizity/otcp/maps/places',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapListView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/map/:mapId/edit/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapEditView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/map/:mapId/settings/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapSettingsView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/map/settings/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapSettingsView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/map/settings/duplicate/:duplicateMapId/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapSettingsView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/poi/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/PoiEditView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/poi/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/PoiEditView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/landmark/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/LandmarkEditView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/landmark/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/LandmarkEditView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },

      /* ----------------------------------- MAPS - WALKING ROUTES ----------------------------------*/
      {
        exact: true,
        path: '/vizity/otcp/maps/walkingRoutes',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapListView')),
        permissionId: PERMISSION_ID.OTCP_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapWalkingRoutePoi/map/:mapId/sheet/:sheetId/poi/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesStepEditView')),
        permissionId: PERMISSION_ID.OTCP_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapWalkingRoutePoi/map/:mapId/sheet/:sheetId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesStepEditView')),
        permissionId: PERMISSION_ID.OTCP_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapWalkingRoutePoi/map/:mapId/sheet/:sheetId/cp/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesCpEditView')),
        permissionId: PERMISSION_ID.OTCP_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapWalkingRoutePoi/map/:mapId/sheet/:sheetId/edit/cp/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesCpEditView')),
        permissionId: PERMISSION_ID.OTCP_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapWalkingRoute/map/:mapId/sheet/:sheetId/editInfo/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesInfosEditView')),
        permissionId: PERMISSION_ID.OTCP_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapWalkingRoute/map/:mapId/sheet/:sheetId/editPath/',
        component: lazy(() => import('src/views/vizity/otcp/maps/walking-routes/MapWalkingRoutesPathEditView')),
        permissionId: PERMISSION_ID.OTCP_WALKING_ROUTES_MAPS
      },

      /* ----------------------------------- MAPS - MULTI-WALKING ROUTES ----------------------------------*/
      {
        exact: true,
        path: '/vizity/otcp/maps/multiWalkingRoutes',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapListView')),
        permissionId: PERMISSION_ID.OTCP_MULTI_WALKING_ROUTES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/map/:mapId/edit/multiWalkingRoutes/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/multi-waling-routes/MapMultiWalkingRoutesEditView')),
        permissionId: PERMISSION_ID.OTCP_MULTI_WALKING_ROUTES_MAPS
      },

      /* ----------------------------------- DATA - UNMISSABLE PLACES ----------------------------------*/
      {
        exact: true,
        path: '/vizity/otcp/data/unmissable/places',
        component: lazy(() => import('src/views/vizity/otcp/data/unmissable-places/DataUnmissablePlacesEditView')),
        permissionId: PERMISSION_ID.OTCP_UNMISSABLE_PLACES
      },
      {
        exact: true,
        path: '/vizity/otcp/data/unmissable-places/sheet/:sheetId/poi/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/PoiEditView')),
        permissionId: PERMISSION_ID.OTCP_UNMISSABLE_PLACES
      },
      {
        exact: true,
        path: '/vizity/otcp/data/unmissable-places/sheet/:sheetId/poi/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/PoiEditView')),
        permissionId: PERMISSION_ID.OTCP_UNMISSABLE_PLACES
      },
      /* ----------------------------- CONFIGURATION - DEFAULT SETTINGS ----------------------------------*/
      {
        exact: true,
        path: '/vizity/otcp/conf/default-settings',
        permissionId: PERMISSION_ID.OTCP_DEFAULT_SETTINGS,
        component: lazy(() => import('src/views/vizity/otcp/default-settings'))
      }
    ]
  },
  {
    path: '/vizity/otcpMembers',
    guard: AuthGuard,
    layout: DashboardLayout,
    otcpMember: true,
    routes: [
      /* ----------------------------------- MAPS - PARTNERS - AROUND MY BUSINESS ----------------------------------*/
      {
        exact: true,
        path: '/vizity/otcpMembers/maps/aroundMyBusiness',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapListView')),
        permissionId: PERMISSION_ID.OTCP_MEMBERS_AROUND_MY_BUSINESS
      },
      {
        exact: true,
        path: '/vizity/otcpMembers/map/:mapId/edit/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapEditView')),
        permissionId: PERMISSION_ID.OTCP_MEMBERS_AROUND_MY_BUSINESS
      },
      {
        exact: true,
        path: '/vizity/otcpMembers/map/:mapId/settings/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapSettingsView')),
        permissionId: PERMISSION_ID.OTCP_MEMBERS_AROUND_MY_BUSINESS
      },
      /*
      {
        exact: true,
        path: '/vizity/otcpMembers/map/settings/typology/:typology',
        component: lazy(() => import('src/views/vizity/otcp/maps/common/MapSettingsView')),
        permissionId: PERMISSION_ID.OTCP_MEMBERS_AROUND_MY_BUSINESS
      },
      */
      {
        exact: true,
        path: '/vizity/otcpMembers/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/poi/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/PoiEditView')),
        permissionId: PERMISSION_ID.OTCP_MEMBERS_AROUND_MY_BUSINESS
      },
      {
        exact: true,
        path: '/vizity/otcpMembers/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/poi/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/PoiEditView')),
        permissionId: PERMISSION_ID.OTCP_MEMBERS_AROUND_MY_BUSINESS
      }
      /*
      {
        exact: true,
        path: '/vizity/otcp/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/landmark/:poiId/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/LandmarkEditView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },
      {
        exact: true,
        path: '/vizity/otcp/maps/mapPlacesPoi/map/:mapId/sheet/:sheetId/landmark/edit/',
        component: lazy(() => import('src/views/vizity/otcp/common/LandmarkEditView')),
        permissionId: PERMISSION_ID.OTCP_PLACES_MAPS
      },
      */
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app" />
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
