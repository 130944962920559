import {
  TYPOLOGY_ID,
  MAP_OPTIONS_CSS,
  MAP_OPTIONS_MAPBOX_STYLE,
  MAP_OPTIONS_MAPBOX_STYLE_GREY,
  MAP_OPTIONS_STYLES_MAP,
  PERMISSION_MAP,
  LAYER_ID
} from 'src/vizity/constantsVizity';

export const DEFAULT_MAP_FACTORY_GOOGLE_API_CALLS_MAX = 50;
export const DEFAULT_MAP_FACTORY_MAPS_MAX = 15;

export const GOOGLE_SHEET_CURRENT_TEMPLATE_ID = 2;

export const MAP_FACTORY_DEFAULT_LOCALE = 'fr';

export const MAP_FACTORY_SUPPORTED_LOCALES = ['fr', 'en', 'es', 'de'];

export const MAP_FACTORY_MAP_OPTIONS_MAPBOX_TOKEN = [
  'pk.eyJ1IjoibG9zdG1hbiIsImEiOiJjamdncHFnZzgwMGtlMnFrMTd0ZHBseXJqIn0.yMCe0vlqfrQF6TlSGCbVog',
  'pk.eyJ1Ijoid2hlcmVtYWdhemluZW1hcCIsImEiOiJjamdnZGt6Yjg0N2hvMnFsaXh0NGdzOHR0In0.8NbXsPulbCPByxrWfL5eYw',
  'pk.eyJ1Ijoia29hbGFtYXAiLCJhIjoiY2pnZ2x4bG5lMWtwbzJycWF0OW52cGF1diJ9.CB6N4DD575QGTs3NfXhuBQ',
  'pk.eyJ1IjoiZmVubmVjZHViYWkiLCJhIjoiY2poN211dG56MDgydzJ6bzEzNTZybWp0MSJ9.b8Ohg20VoXgcvvrbx-LYmQ',
  'pk.eyJ1IjoiZmF1cmVsZW85NSIsImEiOiJjazFxZzR0bHAxM3Y4M21vNjEycmRveWM5In0.rpsAekrtXPpPDkFXdqL0PA',
  'pk.eyJ1IjoibGFmb25jZWNpbGU5NCIsImEiOiJjazFxZzczbTQxMjBiM2RrNjhkb2s3d3R6In0.r0HzuZ-P2Up56IEke8M1qw',
  'pk.eyJ1Ijoicm9ycXVhbGJydXlhbnQiLCJhIjoiY2pxejNqZjAyMDlnZTQ4cGMzdDMydjl6MiJ9.cFUthAilwkj4xkFNHmOstg',
  'pk.eyJ1IjoiZ2FsZ29jaGFzc2V1ciIsImEiOiJjanF6MXE5dXUwMW5mNDNxcXNrZmpuczhvIn0.smuGWKhzJoGAmayQQWIixQ',
  'pk.eyJ1Ijoicm91c3NldHRlYWZyaWNhaW5lIiwiYSI6ImNqeTdndDY1ajAwc2czaW8xaXVobTdneGUifQ.Y9AoSEManYfxG6SneWCJGg',
  'pk.eyJ1IjoiYWx1Y29ub2N0dXJuZSIsImEiOiJjanJodWFybmwxaTk0M3lvZ2ljYjducHF0In0.qoVnxoJJ-LiEAH0ehFzVog',
  'pk.eyJ1IjoiYWxiYXRyb3NhbHRydWlzdGUiLCJhIjoiY2psZjRwbjM5MDlvdzNxcDFycXo3YXk3ZiJ9.vKXd9xWowqzfELEM7N0RvQ',
  'pk.eyJ1Ijoidml6aXR5IiwiYSI6ImNpcnc4bHRnajAwZzVoc2t3cGllb3c1eDIifQ.haz1xsVmbDfqxLLFT3gYXA',
  'pk.eyJ1IjoiYWJlaWxsZWhhYmlsZSIsImEiOiJjamxmNG4yZTUwdDNrM3FwYzRub3JveWx5In0.CJchYKitd3wieD1OFpFexw'
];

export const MAP_FACTORY_SUBSCRIPTIONS = {
  STARTER_2022: {
    name: 'Starter',
    active: true,
    maps: 1,
    google_api_calls: 30,
    order: 1
  },
  BUSINESS_2022: {
    name: 'Business',
    active: true,
    maps: 5,
    google_api_calls: 50,
    order: 2
  },
  PREMIUM_2022: {
    name: 'Premium',
    active: true,
    maps: 15,
    google_api_calls: 100,
    order: 3
  },
  CUSTOM_2022: {
    name: 'Custom',
    active: true,
    maps: 10000,
    google_api_calls: 10000,
    order: 4
  }
};

export const MAP_FACTORY_MAP_PLACES_TEMPLATE = {
  title: {
    fr: ''
  },
  lang: MAP_FACTORY_DEFAULT_LOCALE,
  organization_id: '{{ORGANIZATION_ID}}',
  status: 1,
  typology_id: TYPOLOGY_ID.MAP_FACTORY_PLACES,
  options: {
    css: MAP_OPTIONS_CSS,
    bbox: '{{BBOX}}',
    langs: [MAP_FACTORY_DEFAULT_LOCALE],
    popup: {
      anchor: 'left',
      offset: [16, 0]
    },
    back_office: {
      internal_name: ''
    },
    mapbox: {
      style: MAP_OPTIONS_MAPBOX_STYLE,
      token: MAP_FACTORY_MAP_OPTIONS_MAPBOX_TOKEN
    },
    styles: {
      map: MAP_OPTIONS_STYLES_MAP
    },
    map_controls: [
      {
        type: 'NavigationControl',
        options: {
          compass: false
        }
      },
      {
        type: 'GeolocateControl',
        actions: [
          {
            options: {
              max_radius: 10,
              init_radius: 0.3
            },
            function: 'setMapCenter'
          }
        ],
        visibility: 'visible'
      },
      {
        type: 'FullscreenControl'
      }
    ],
    zoom_on_open: 16
  },
  layers: [{ layer_id: '{{LAYER_ID.GENERIC_POI}}', idx: 1 }],
  //widgets: [313, 357, 365, 366, 367, 368, 369, 370, 371, 372, 373, 411, 412, 413, 414],
  widgets: [313, 357, 365, 366, 367, 368, 369, 370, 371, 372, 373, 425, 426, 427, 428],
  sheets: [
    {
      sheet_id: '{{SHEET_ID.PLACES}}',
      idx: 1,
      options: {
        advanced_filters: [
          {
            filter: [
              {
                key: 'map_id',
                value: '%map_id%',
                operator: '@>'
              }
            ]
          }
        ]
      }
    }
  ],
  permissions: [...Object.values(PERMISSION_MAP)]
};

export const MAP_FACTORY_MAP_MULTI_WALKING_ROUTES_TEMPLATE = {
  title: {
    fr: ''
  },
  lang: MAP_FACTORY_DEFAULT_LOCALE,
  organization_id: '{{ORGANIZATION_ID}}',
  status: 1,
  typology_id: TYPOLOGY_ID.MAP_FACTORY_MULTI_WALKING_ROUTES,
  options: {
    css: MAP_OPTIONS_CSS,
    bbox: '{{BBOX}}',
    langs: [MAP_FACTORY_DEFAULT_LOCALE],
    popup: {
      anchor: 'left',
      offset: [16, 0]
    },
    back_office: {
      internal_name: ''
    },
    mapbox: {
      style: MAP_OPTIONS_MAPBOX_STYLE_GREY,
      token: MAP_FACTORY_MAP_OPTIONS_MAPBOX_TOKEN
    },
    styles: {
      map: MAP_OPTIONS_STYLES_MAP
    },
    map_controls: [
      {
        type: 'NavigationControl',
        options: {
          compass: false
        }
      },
      {
        type: 'GeolocateControl',
        actions: [
          {
            options: {
              max_radius: 10,
              init_radius: 0.3
            },
            function: 'setMapCenter'
          }
        ],
        visibility: 'visible'
      },
      {
        type: 'FullscreenControl'
      }
    ],
    zoom_on_open: 13
  },
  layers: [{ layer_id: '{{LAYER_ID.MULTI_WALKING_ROUTES_POI}}', idx: 1 }],
  // FIXME !
  widgets: [273, 275, 302, 313, 338, 436, 385, 386, 387, 388, 389, 391], //436 - > 383
  sheets: [
    {
      sheet_id: '{{SHEET_ID.WALKING_ROUTES}}',
      idx: 1,
      options: {
        advanced_filters: [
          {
            filter: [
              {
                key: 'mutli_map_id',
                value: '%map_id%',
                operator: '@>'
              }
            ]
          }
        ],
        filters: {
          lang: {
            url_search_parameter: 'lang'
          }
        }
      }
    }
  ],
  permissions: [...Object.values(PERMISSION_MAP)]
};

export const MAP_FACTORY_MAP_WALKING_ROUTE_TEMPLATE = {
  title: {
    fr: ''
  },
  lang: MAP_FACTORY_DEFAULT_LOCALE,
  organization_id: '{{ORGANIZATION_ID}}',
  status: 1,
  typology_id: TYPOLOGY_ID.MAP_FACTORY_WALKING_ROUTES,
  options: {
    css: MAP_OPTIONS_CSS,
    bbox: '{{BBOX}}',
    langs: [MAP_FACTORY_DEFAULT_LOCALE],
    popup: {
      anchor: 'left',
      offset: [16, 0]
    },
    back_office: {
      internal_name: ''
    },
    mapbox: {
      style: MAP_OPTIONS_MAPBOX_STYLE,
      token: MAP_FACTORY_MAP_OPTIONS_MAPBOX_TOKEN
    },
    styles: {
      map: MAP_OPTIONS_STYLES_MAP
    },
    map_controls: [
      {
        type: 'NavigationControl',
        options: {
          compass: false
        }
      },
      {
        type: 'GeolocateControl',
        actions: [
          {
            options: {
              max_radius: 10,
              init_radius: 0.3
            },
            function: 'setMapCenter'
          }
        ],
        visibility: 'visible'
      },
      {
        type: 'FullscreenControl'
      }
    ],
    zoom_on_open: 16
  },
  layers: [
    //317 -> 407
    { layer_id: '{{LAYER_ID.WALKING_ROUTES_POI}}', idx: 1 }, //327 -> 408
    { layer_id: '{{LAYER_ID.WALKING_ROUTES}}', idx: 2 }
  ],
  //widgets: [273, 275, 306, 313, 314, 324, 326, 327, 328, 329, 330, 331, 332, 335, 336, 337, 338],
  /*
  273 -> 365
//275 -> 357
275 -> 406
313 -> 313 : locator style
314 -> 314
324 -> 398
326 -> 399
327 -> 400
328 -> 401
329 -> 402
330 -> 403 
331 -> 404
332 -> 405
  335 -> A propos
  336 -> Filtres supplémentaires
  337 -> tabs
          => 325 -> 409
338 -> 338

334 -> 410
  */
  //widgets: [357, 365, 313, 314, 398, 399, 400, 401, 402, 403, 404, 405, 338, 409, 410],
  widgets: [357, 365, 313, 314, 338, 412, 413, 414, 415, 416, 417, 418, 419, 423, 424],
  sheets: [
    {
      sheet_id: '{{SHEET_ID.WALKING_ROUTES_POI}}',
      idx: 1,
      options: {
        advanced_filters: [
          {
            filter: [
              {
                key: 'map_id',
                value: '%map_id%',
                operator: '@>'
              }
            ]
          }
        ]
      }
    },
    {
      sheet_id: '{{SHEET_ID.WALKING_ROUTES}}',
      idx: 2,
      options: {
        filters: {
          map_id: '%map_id%',
          lang: {
            url_search_parameter: 'lang'
          }
        }
      }
    }
  ],
  permissions: [...Object.values(PERMISSION_MAP)]
};

export const MAP_FACTORY_MAP_GOOGLE_SHEET_TEMPLATE = {
  title: {
    fr: ''
  },
  lang: MAP_FACTORY_DEFAULT_LOCALE,
  organization_id: '{{ORGANIZATION_ID}}',
  status: 1,
  typology_id: TYPOLOGY_ID.MAP_FACTORY_GOOGLE_SHEETS,
  options: {
    css: MAP_OPTIONS_CSS,
    bbox: '{{BBOX}}',
    langs: [MAP_FACTORY_DEFAULT_LOCALE],
    popup: {
      anchor: 'left',
      offset: [16, 0]
    },
    back_office: {
      internal_name: ''
    },
    mapbox: {
      style: MAP_OPTIONS_MAPBOX_STYLE_GREY,
      token: MAP_FACTORY_MAP_OPTIONS_MAPBOX_TOKEN
    },
    styles: {
      map: MAP_OPTIONS_STYLES_MAP
    },
    map_controls: [
      {
        type: 'NavigationControl',
        options: {
          compass: false
        }
      },
      {
        type: 'GeolocateControl',
        actions: [
          {
            options: {
              max_radius: 10,
              init_radius: 0.3
            },
            function: 'setMapCenter'
          }
        ],
        visibility: 'visible'
      },
      {
        type: 'FullscreenControl'
      }
    ],
    zoom_on_open: 16,
    actions_mobile: {
      _rendered_pois_user_layer_207: [
        {
          options: {
            poi: [['data._.slug', '=', '{{url.slug}}']],
            highlight: true
          },
          function: 'center-on-poi'
        }
      ]
    },
    url_parameters: {
      slug: {
        type: 'string'
      }
    },
    actions_desktop: {
      _rendered_pois_user_layer_207: [
        {
          options: {
            poi: [['data._.slug', '=', '{{url.slug}}']],
            'center-on': true,
            highlight: true
          },
          function: 'open-poi'
        }
      ]
    }
  },
  layers: [
    { layer_id: LAYER_ID.LAYER_MF_SPRITE_MARKER, idx: 1, layer_options: { sheet: '%SHEET_ID%' } },
    { layer_id: LAYER_ID.LAYER_MF_COLORED_CIRCLE, idx: 2, layer_options: { sheet: '%SHEET_ID%' } },
    { layer_id: LAYER_ID.LAYER_MF_TEXT, idx: 3, layer_options: { sheet: '%SHEET_ID%' } }
  ],
  widgets_with_options: [
    { widget_id: 440 },
    { widget_id: 441 },
    { widget_id: 442 },
    { widget_id: 443 },
    { widget_id: 444 },
    { widget_id: 445 },
    { widget_id: 446 },
    { widget_id: 447 },
    { widget_id: 448 },
    { widget_id: 449 },
    { widget_id: 450, widget_options: { target: { mode: 'all', sheets: ['%SHEET_ID%'], order_column: 'data.row_number', forward_filters: true } } },
    { widget_id: 451 },
    { widget_id: 452 },
    { widget_id: 453 },
    { widget_id: 454 },
    { widget_id: 455 },
    { widget_id: 457 },
    {
      widget_id: 458,
      widget_options: {
        targets: [
          {
            type: 'options',
            label: {
              de: '<span class="viz-label">{{value}}</span>',
              en: '<span class="viz-label">{{value}}</span>',
              es: '<span class="viz-label">{{value}}</span>',
              fr: '<span class="viz-label">{{value}}</span>'
            },
            sheet: '%SHEET_ID%',
            column: 'filter',
            target: 'all',
            trContext: ''
          }
        ]
      }
    }
  ],
  sheets: [
    {
      sheet_id: '%SHEET_ID%',
      idx: 1
    }
  ],
  permissions: [...Object.values(PERMISSION_MAP)]
};

export const MF_HTML_TEMPLATE_DESKTOP_MAP_WIDTH = '700px';
export const MF_HTML_TEMPLATE_DESKTOP_MAP_HEIGHT = '500px';
export const MF_HTML_TEMPLATE_MOBILE_MAP_WIDTH = '340px';
export const MF_HTML_TEMPLATE_MOBILE_MAP_HEIGHT = '450px';

export const MF_ORGANIZATION_DEFAULT_OPTIONS = {
  map_factory: {
    maps: {},
    layers: {
      generic_poi: 188,
      walking_routes: 189,
      walking_landmark: 191,
      walking_routes_poi: 190,
      multi_walking_routes_poi: 192,
      mf_colored_pin: 195,
      mf_colored_text: 196,
      mf_sprite_marker: 207
    },
    sheets: {
      places: 223,
      walking_routes: 224,
      walking_routes_poi: 225,
      google_sheet_generic: 228
    },
    subscription: Object.keys(MAP_FACTORY_SUBSCRIPTIONS)[0],
    subscription_date: '',
    register_date: '',
    have_seen_disclaimer_message: true,
    unsubscribe_date: ''
  },
  translation: {
    'use.organization.id': 52
  },
  back_office: {
    default_settings: {
      bbox: {
        zoom_init: 4.96733626629629,
        init_bounds: [-11.301117173233846, 41.94914553876623, 15.669853148190182, 51.187694340073264],
        center_init_lat: 46.76600490610071,
        center_init_lng: 2.1843679874778115
      },
      mapbox: {
        pitch: 0,
        style: 'mapbox://styles/vizity/ck1q51ehh02np1ctauoix7yhu'
      },
      map_controls: [
        {
          type: 'NavigationControl',
          options: {
            zoom: true,
            compass: false
          }
        },
        {
          type: 'GeolocateControl',
          actions: [
            {
              options: {
                max_radius: 10,
                init_radius: 0.3
              },
              function: 'setMapCenter'
            }
          ],
          visibility: 'visible'
        },
        {
          type: 'FullscreenControl'
        }
      ]
    }
  }
};
