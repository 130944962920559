const getErrorMessage = error => {
  if (error != null) {
    if (typeof error === 'string' || error instanceof String) {
      return error;
    } else if ((typeof error === 'object' || error instanceof Object) && 'message' in error) {
      return error.message;
    }
    return String(error);
  }
  return '';
};

export default getErrorMessage;
